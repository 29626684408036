import { EventEmitter, Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GuestField } from '@booking/models/guest.model';
import { RatePlanType } from '@shared/api/be-api.generated';

export type FlightsEnabledInput = {
  isEnabled: boolean;
  ratePlanType?: RatePlanType;
}

@Injectable()
export class ComponentsCommunicationService {
  flightsEnabled = new EventEmitter<FlightsEnabledInput>(); // string is requested rate plan
  openFlightsSection = new EventEmitter();
  addonFilterUpdated = new EventEmitter();
  mainAdultChanged = new EventEmitter<FormGroup<Record<GuestField, FormControl>>>();
}
