<ng-container *transloco="let t">
  <div class="cart-viewer full-size">
    @if(departureAirport){
    <div class="flex-between-start gap-half">
      <app-icon
        class="margin-top-quarter"
        name="airplane"
        filled="transparent"
        stroked="textPrimary">
      </app-icon>
      <div class="flex-column margin-right-auto">
        <div class="link flex-start gap-half cursor-pointer"
          (click)="toggleFlights()">
          {{t('cart.header.flights')}}
          <app-icon
            stroked="selected"
            [name]="isOpenedFlights ? 'up-two': 'down-two'">
          </app-icon>
        </div>
        <div class="text-0_75-1_25-600">
          {{t('cart.fromTo', {
          from: departureAirport.city + ' (' + departureAirport.code + ')',
          to: arrivalAirport?.city
          })}}
        </div>
        <div class="text-0_75-1_125-500">
          {{ arrival | appDate: 'type8' }} - {{ departure | appDate: 'type8' }}
        </div>
      </div>
    </div>
    <div class="flex-column collapsable"
      [class.collapsed]="!isOpenedFlights">
      <app-flight-journey
        [isOnlyMobileView]="true"
        [journey]="inboundJourney">
      </app-flight-journey>
      <app-flight-journey
        [isOnlyMobileView]="true"
        [journey]="outboundJourney">
      </app-flight-journey>
    </div>
    <div class="divider-dotted"></div>
    }

    <div class="flex-between-start gap-quarter cursor-pointer">
      <app-icon
        name="resort"
        class="margin-top-quarter"
        stroked="transparent"
        filled="textPrimary">
      </app-icon>
      <div class="flex-column margin-right-auto">
        <div class="link flex-start gap-half cursor-pointer"
          (click)="toggleHotel()">
          {{roomType?.caption}}
          <app-icon stroked="selected"
            [name]="isOpenedResort ? 'up-two': 'down-two'">
          </app-icon>
        </div>
        <div class="text-0_75-1_25-600">
          {{resort?.caption}}, {{resort?.address?.country?.name}}
        </div>
        <div class="text-0_75-1_125-500">
          {{ arrival | appDate: 'type8' }} - {{ departure | appDate: 'type8' }}
        </div>
        <div class="text-0_75-1_125-500">
          {{t(adults > 1 ? 'adult.count.plural': 'adult.count.singular', { value: adults } )}},&nbsp;
          @if(children){
          {{t(children > 1 ? 'child.count.plural': 'child.count.singular', { value: children } )}},&nbsp;
          }
          {{periodNights | nights}}
        </div>

        <div class="flex-column collapsable"
          [class.collapsed]="!isOpenedResort">
          @if(specialOffer){
          <app-special-offer-caption
            [currentSpecialOffer]="specialOffer"
            [isVisibleNotes]="true">
          </app-special-offer-caption>
          }
          <app-non-monetary-v2
            [nonMonetary]="nonMonetary">
          </app-non-monetary-v2>
        </div>
      </div>
    </div>

    <div class="divider-dotted"></div>

    <ng-template #transferView
      let-airportTransfer="airportTransfer"
      let-totalPrice="totalPrice"
      let-type="type">
      @if(airportTransfer){
      <div class="margin-top flex-between flex-wrap gap-half">
        <div class="text-1-1_25-600">
          {{airportTransfer.name}}
        </div>
        <small class="text-0_75-1_125-500 color-textInactive margin-left-auto">
          ({{t(['airportTransfer', type] | join: '.')}})
        </small>
      </div>
      <div class="flex-between gap-half">
        @if(arrivalAirport){
        <div class="text-0_875-1_125-500">
          {{t( type === 'inbound'
          ? 'airportTransfer.fromAirportToHotel'
          : 'airportTransfer.fromHotelToAirport',
          { value: arrivalAirport.name })}}
        </div>
        }
        <div class="price-text">
          @if (totalPrice){
          <div class="text-0_875-1_125-500 no-wrap">
            <span class="color-textInactive">{{airportTransfer.price | appCurrency}}&nbsp;x&nbsp;{{adults + children}}&nbsp;=&nbsp;</span>
            {{totalPrice | appCurrency}}
          </div>
          }
          @else {
          <div class="color-textSuccessPrice">
            {{t('price.complimentary')}}
          </div>
          }
        </div>
      </div>
      <div class="divider-dotted"></div>
      }
    </ng-template>

    <ng-container *ngTemplateOutlet="transferView; context: {
      airportTransfer: inboundTransfer,
      totalPrice: inboundTransferTotal,
      type: 'inbound'
    }"></ng-container>

    <ng-container *ngTemplateOutlet="transferView; context: {
      airportTransfer: outboundTransfer,
      totalPrice: outboundTransferTotal,
      type: 'outbound'
    }"></ng-container>

    @if (addOns.length){
    <div class="margin-top text-1-1_25-600">
      {{t('cart.header.addons')}}
    </div>
    <div class="flex-column gap-half">
      @for (addOn of addOns; track $index) {
      <div class="flex-between gap-half"
        [@cartAddOnEnter]
        [@cartAddOnLeave]>
        <div class="text-0_875-1_125-500">
          {{addOn.name}}
          @if(addOn.ageGroupId){
          <div>
            {{t('cart.fromTo', { from: addOn.from, to: addOn.to })}}
          </div>
          }
        </div>
        <div class="text-0_875-1_125-500 no-wrap">
          @if (addOn.priceTotal > 0){
          <span class="color-textInactive">{{addOn.pricePerItem | appCurrency}}&nbsp;x&nbsp;{{addOn.quantity}}&nbsp;=&nbsp;</span>
          {{addOn.priceTotal | appCurrency}}
          }
          @else {
          <div class="color-textSuccessPrice">
            {{t('price.complimentary')}}
          </div>
          <div class="color-textInactive">&nbsp;x&nbsp;{{addOn.quantity}}</div>
          }
        </div>
      </div>
      <div [@cartAddOnEnter]
        [@cartAddOnLeave]>
        @if (isCouplesTenant && addOn.categoryCode === tripProtectionCategory){
        <div class="text-0_75-0_75-400"
          [innerHTML]="t('couples.tripProtection.description') | safe: 'html'">
        </div>
        }
      </div>
      }
    </div>
    <div class="divider-dotted"></div>
    }

    @if(saved > 1){
    <div class="flex-column gap-quarter text-0_75-1_125-500">
      <div class="flex-between gap">
        <span>{{t('cart.header.basePrice')}}</span>
        <span class="margin-left-auto">{{baseTotal | appCurrency}}</span>
      </div>
      <div class="flex-between gap">
        @if(profile){
        <app-info-label
          [text]="t('member.savings', { value: (memmberSaved | appCurrency) })"
          type="memberDefault"
          textColor="memberText">
        </app-info-label>
        <span class="fw-500 margin-left-auto color-textSuccessPrice">- {{saved | appCurrency}}</span>
        }@else{
        <span class="fw-500 margin-left-auto color-textSuccessPrice">
          {{t('cart.subheader.saved', { value: saved | appCurrency })}}
        </span>
        }
      </div>
    </div>
    <div class="divider-dotted"></div>
    }
  </div>
</ng-container>
