<ng-container *transloco="let t">
  <div class="flex-column">
    <div class="section">
      <div class="section-header">
        <div class="section-header-text">
          {{t('creditCard.section.header')}}
        </div>
      </div>
    </div>
    <app-form
      #fc
      [form]="form"
      [scope]="scope"
      [anchorType]="anchorType">
      <ng-template #content
        let-text="text"
        let-creditCard="creditCard"
        let-subLabel="subLabel">
        @if(!isTwoLineCard){
        <div class="flex-column">
          <ng-container *ngTemplateOutlet="creditCard; context: { fields: {
              cardNumber: 'cardNumber',
              expirationDate: 'expirationDate',
              securityCode: 'securityCode'
          }}"></ng-container>
        </div>
        }
        @if(isTwoLineCard){
        <div class="flex-between gap">
          <div class="flex-column w-100">
            <ng-container *ngTemplateOutlet="text; context: { field: 'cardNumber', autocomplete: 'cc-number', nextFieldId: 'expirationDate' }"></ng-container>
            <div class="flex-between gap">
              <div class="flex-column w-100">
                <ng-container *ngTemplateOutlet="text; context: { field: 'expirationDate', autocomplete: 'cc-exp', nextFieldId: 'securityCode' }"></ng-container>
              </div>
              <div class="flex-column w-100">
                <ng-container *ngTemplateOutlet="text; context: { field: 'securityCode', autocomplete: 'cc-csc', type: 'number' }"></ng-container>
              </div>
            </div>
            <div class="flex-start-center gap-quarter margin-top-half">
              <app-icon
                filled="formControl"
                name="lock">
              </app-icon>
              <ng-container *ngTemplateOutlet="subLabel; context: {
                field: 'creditCard'
              }"></ng-container>
            </div>
          </div>
        </div>
        }
        <!-- <div class="flex-between gap-desktop-min flex-column-desktop-max">
          <div class="card flex-100 flex-center-start">
            @if (form) {
            <app-credit-card #creditCard [form]="form"></app-credit-card>
            }
          </div>
          <div class="card-data flex-100">
            <div class="flex-column">
              <ng-container *ngTemplateOutlet="text; context: {
                field: 'cardNumber',
                autocomplete: 'cc-number'
              }"></ng-container>
            </div>
            <div class="flex-between gap">
              <div class="flex-column w-100">
                <ng-container *ngTemplateOutlet="text; context: {
                  field: 'expirationDate',
                  autocomplete: 'cc-exp'
                }"></ng-container>
              </div>
              <div class="flex-column w-100">
                <ng-container *ngTemplateOutlet="text; context: {
                  field: 'securityCode',
                  autocomplete: 'cc-csc',
                  type: 'number'
                }"></ng-container>
              </div>
            </div>
            @if(full){
            <div class="flex-column">
              <ng-container *ngTemplateOutlet="text; context: {
                 field: 'name',
                 autocomplete: 'cc-name'
              }"></ng-container>
            </div>
            }
          </div>
        </div> -->
        <div class="flex-column"
          [hidden]="!full">
          <ng-container *ngTemplateOutlet="text; context: { field: 'name', autocomplete: 'cc-name' }"></ng-container>
        </div>
        <div class="address-data"
          [hidden]="!full">
          <div class="flex-between gap">
            <div class="flex-column w-100">
              <ng-container *ngTemplateOutlet="text; context: { field: 'address', autocomplete: 'street-address' }"></ng-container>
            </div>
            <div class="flex-column">
              <ng-container *ngTemplateOutlet="text; context: { field: 'postalCode', autocomplete: 'postal-code' }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </app-form>
  </div>
</ng-container>