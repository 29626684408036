import {
  collapseAnimation,
  fadeInAnimation,
  fadeInDownOnEnterAnimation,
  fadeInOnEnterAnimation,
  fadeInRightAnimation,
  fadeInUpOnEnterAnimation,
  fadeOutAnimation,
  fadeOutDownOnLeaveAnimation,
  fadeOutOnLeaveAnimation,
  heartBeatAnimation,
  pulseAnimation
} from 'angular-animations';

export const sectionChange = pulseAnimation({ duration: 350, anchor: 'sectionChange' });
export const flightChange = fadeInAnimation({ anchor: 'flightChange' });
export const packagePriceChange = heartBeatAnimation({ anchor: 'packagePriceChange', scale: 1.04 });
export const airportTransferChange = heartBeatAnimation({ anchor: 'airportTransferChange', scale: 1.1 });
export const cartPriceChange = heartBeatAnimation({ anchor: 'cartPriceChange', scale: 1.1 });
export const cartAddOn = {
  enter: fadeInOnEnterAnimation({ anchor: 'cartAddOnEnter' }),
  leave: fadeOutOnLeaveAnimation({ anchor: 'cartAddOnLeave' })
};
export const genderChange = pulseAnimation({ anchor: 'genderChange', duration: 300 });
export const airportsVisibilityChange = fadeInAnimation({ anchor: 'airportsVisibilityChange' });
export const resortAmenitiesInChange = fadeOutAnimation({ anchor: 'resortAmenitiesInChange', duration: 500 });
export const resortAmenitiesOutChange = fadeInAnimation({ anchor: 'resortAmenitiesOutChange', duration: 500 });

export const searchBarCollapsedChange = collapseAnimation({ anchor: 'searchBarCollapsedChange', duration: 400 });
export const searchBarAirportChange = collapseAnimation({ anchor: 'searchBarAirportChange', duration: 300 });

export const stepperNextButtonChange = fadeInOnEnterAnimation({ anchor: 'stepperNextButtonChange' });

export const selectTabContentChange = fadeInRightAnimation({ anchor: 'selectTabContentChange', duration: 500 });

export const memberMenuItemChange = fadeInRightAnimation({ anchor: 'memberMenuItemChange', duration: 750 });

export const paymentFormVisibility = {
  enter: fadeInOnEnterAnimation({ anchor: 'paymentFormEnter' }),
  leave: fadeOutDownOnLeaveAnimation({ anchor: 'paymentFormLeave', duration: 400 })
};
