import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MatStepper } from '@angular/material/stepper';

import { map, Observable } from 'rxjs';

import { BookingService } from '@booking/services/booking.service';
import { ComponentBaseService } from '@shared/services/component-base.service';

import { ComponentBase } from '@shared/base/component.base';
import { PaymentEvents, PaymentSection, paymentSections } from '@booking/models/payment.model';
import { LoaderSection, minWidth, screenWidths } from '@shared/models/common';
import { displayNumbersInsteadOfIcons, StepperLabelOrientation } from '@shared/common.material';
import { PaymentFormComponent } from '../payment-form/payment-form.component';

const { flight, hotel, result } = paymentSections;

@Component({
  selector: 'app-payment-stepper',
  templateUrl: './payment-stepper.component.html',
  styleUrls: ['./payment-stepper.component.scss'],
  standalone: false
})
export class PaymentStepperComponent extends ComponentBase implements AfterViewInit {
  constructor(
    private readonly _booking: BookingService,
    breakpointObserver: BreakpointObserver,
    services: ComponentBaseService,
  ) {
    super(services);

    this._setWidthsObservers(breakpointObserver);
  }

  @Input() events!: PaymentEvents;
  @ViewChild('paymentForm') paymentForm?: PaymentFormComponent;
  @ViewChild(MatStepper) stepper!: MatStepper;
  @Output() restartPayment = new EventEmitter();
  @Output() openConfirmation = new EventEmitter();

  email = this._booking.reservationInput.loyalty?.email;
  selectedIndex = 0;
  stepperLabelOrientation!: Observable<StepperLabelOrientation>;
  availableSections: PaymentSection[] = [];
  currentSection!: PaymentSection;
  allSections = paymentSections;
  loaderSection: LoaderSection = 'paymentHotel';

  isSuccess: Partial<Record<PaymentSection, boolean>> = {}
  isLoading: Partial<Record<PaymentSection, boolean>> = {
    hotel: true
  };

  error: Partial<Record<PaymentSection, string>> = {}

  state: Record<PaymentSection, 'success' | 'error' | 'loading' | 'idle'> = {
    hotel: 'loading',
    flight: 'idle',
    result: 'idle'
  };

  needToReTry = false;

  ngAfterViewInit() {
    this.setAvailableSections();
    this.subscribeToPaymentEvents();
    displayNumbersInsteadOfIcons(this.stepper);
    //this.testRun();
  }

  private testRun() {
    window.setTimeout(() => {
      const prev = this.availableSections[this.selectedIndex];
      if (prev) {
        this.isLoading[prev] = false;
        this.isSuccess[prev] = true;
      }

      const next = this.availableSections[this.selectedIndex + 1];
      if (next) {
        this.isLoading[next] = true;
        this.setActiveSection(next);
        this.testRun();
      }
      this.updatePaymentState();
    }, 2000);
  }

  private _setWidthsObservers(breakpointObserver: BreakpointObserver) {
    this.stepperLabelOrientation = breakpointObserver
      .observe(minWidth(screenWidths.tablet, 150))
      .pipe(map<BreakpointState, StepperLabelOrientation>(({ matches }) => (matches ? 'end' : 'bottom')));
  }

  setActiveSection(section: PaymentSection) {
    const newIndex = this.availableSections.findIndex(item => item == section);
    if (newIndex > -1) {
      this.selectedIndex = newIndex;
    }
    this.currentSection = section;
  }

  setAvailableSections() {
    this.availableSections = [hotel];
    if ((this._booking.lastRequest || this._booking.nextRequest)?.flightsToBuy?.itinerary) {
      this.availableSections.push(flight);
    }
    this.availableSections.push(result);
  }

  getNextSection(section: PaymentSection) {
    const index = this.availableSections.findIndex(item => item === section);
    return index + 1 < this.availableSections.length
      ? this.availableSections[index + 1]
      : undefined;
  }

  updatePaymentState() {
    Object.keys(this.state).forEach(key => {
      const section = key as PaymentSection;
      this.state[section] = this.isSuccess[section]
        ? 'success'
        : this.isLoading[section]
          ? 'loading'
          : this.error[section]
            ? 'error'
            : 'idle';
    })
  }

  subscribeToPaymentEvents() {
    this.pushSub(
      this.events.hotel.subscribe(({ error, canRetry }) => {
        this.needToReTry = canRetry || false;
        this.isLoading[hotel] = false;
        this.error[hotel] = error;
        this.isSuccess[hotel] = !error;

        if (this.isSuccess[hotel]) {
          const nextSection = this.getNextSection(hotel);
          if (nextSection === flight) {
            this.setActiveSection(flight);
            this.isLoading[flight] = true;
          } else {
            this.setActiveSection(result);
            this.isSuccess[result] = true;
          }
        }

        this.updatePaymentState();
      }),

      this.events.flight.subscribe(({ error }) => {
        this.isLoading[flight] = false;
        this.error[flight] = error;
        this.isSuccess[flight] = !error;

        this.setActiveSection(result);
        this.isSuccess[result] = true;

        this.updatePaymentState();
      })
    );
  }

  restrartPayment() {
    this.error[hotel] = undefined;
    this.isLoading[hotel] = true;
    this.updatePaymentState();
    this.paymentForm?.updateReservation();
    this.restartPayment.emit();

    // window.setTimeout(() => {
    //   this.events.hotel.emit();

    //   const nextSection = this.getNextSection(hotel);
    //   if (nextSection === flight) {
    //     window.setTimeout(() => {
    //       this.events.flight.emit();
    //     }, 5000);
    //   }
    // }, 2000);
  }

  openConfirmationPage() {
    this.openConfirmation.emit();
  }
}
