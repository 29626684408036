import { Pipe, PipeTransform } from '@angular/core';
import { IconScope } from '@shared/models/common';

@Pipe({
  name: 'iconPath',
  standalone: false
})
export class IconPathPipe implements PipeTransform {
  transform(name: string, scope: IconScope = 'icons', format = 'svg') {
    return `assets/${scope}/${name}.${format}`;
  }
}
