<ng-container *transloco="let t">
  @if(addOn){
  <app-anchor type="protectVacationError"></app-anchor>
  <div class="protect-vacation flex-column">
    <div class="section-header">
      <div class="section-header-text">
        {{t('protectVacation.section.header')}}
      </div>
      <app-info-label
        [text]="t('protectVacation.encouraged')"
        type="successInfoLabel">
      </app-info-label>
      <app-section-toggler #toggler
        [hidden]="true">
      </app-section-toggler>
    </div>
    <div class="collapsable"
      [class.collapsed]="toggler.isHidden">
      <div>
        <div class="label collapsable margin-top-less"
          [class.collapsed]="isShort.label"
          *ngIf="!isTouched || isYesSelected !== undefined; else error">
          <ng-container *ngIf="topLinks.length">
            <a *ngFor="let link of topLinks"
              class="color-selected"
              [href]="link"
              target="_blank">
              {{link}}
            </a>
            <br>
            <br>
          </ng-container>
          {{t('protectVacation.select.question')}}
          <b class="color-required">&nbsp;*</b>
        </div>
        <ng-template #error>
          <div class="label collapsable margin-top-less"
            [class.collapsed]="isShort.label">
            <mat-error class="error-message bold">
              <span [innerHtml]="t('protectVacation.select.required')"></span>
            </mat-error>
          </div>
        </ng-template>
        <div class="value flex-column"
          [class.gap]="!isShort.component">
          <div class="flex-between padding gap-0_75 bc-successBackground"
            [class.padding-bottom-less]="isShort.label"
            [class.cursor-pointer]="!isYesSelected">
            <div class="flex-column full-size"
              [class.gap]="isShort.label">
              <div class="flex-between">
                <div class="flex-start gap-quarter text-0_75-1-500 flex-1">
                  <app-icon
                    (click)="select(true)"
                    name="checked"
                    size="default"
                    [class.active]="isYesSelected"
                    [class.cursor-pointer]="!isShort.component"
                    [class.bc-successBackground]="isYesSelected"
                    class="checkbox-icon">
                  </app-icon>
                  <span (click)="select(true)"
                    class="text-1-1_25-600"
                    [class.cursor-pointer]="!isShort.component">
                    {{t('protectVacation.select.yesOption')}}
                  </span>
                  <span [hidden]="!isShort.price"
                    class="link-underlined margin-left-auto"
                    (click)="closeShortForm()">
                    {{t('button.change')}}
                  </span>
                </div>
                <div class="flex-column margin-top-minus-half"
                  *ngIf="addOn.pricingModel && !isShort.price">

                  @if (discountedPrice) {
                  <div class="flex-between gap-quarter">
                    <div class="text-1_125-1_5-600 text-center color-textInactive line-through">
                      {{addOn.pricingModel.price | appCurrency}}
                    </div>
                    <div class="text-1_125-1_5-600 text-center">
                      {{discountedPrice | appCurrency}}
                    </div>
                  </div>
                  }
                  @else {

                  @if ((addOn.pricingModel.price || 0) > 0){
                  <div class="text-1_125-1_5-600 text-center">
                    {{addOn.pricingModel.price | appCurrency}}
                  </div>
                  }
                  @else {
                  <div class="text-1_125-1_5-600 text-center">
                    {{t('price.complimentary')}}
                  </div>
                  }

                  }
                  <div class="text-0_75-1_25-400 no-wrap uppercased">
                    {{addOn.pricingModel.type | addOnType}}
                  </div>
                </div>
              </div>
              <div class="block-description"
                appTargetBlank
                [class.collapsed]="isShort.component"
                [class.color-successBackground]="isShort.component"
                [innerHtml]="addOn.description | safe: 'html'">
              </div>
            </div>
          </div>
          <div class="collapsable"
            [class.collapsed]="isShort.component">
            <div class="flex-between padding gap-0_75 bc-background"
              [class.cursor-pointer]="isYesSelected !== false"
              (click)="select(false)">
              <div class="flex-start-start gap-quarter text-0_75-1-500 flex-1">
                <app-icon
                  name="checked"
                  size="default"
                  [class.active]="isYesSelected === false"
                  [class.bc-background]="isYesSelected === false"
                  class="checkbox-icon">
                </app-icon>
                {{t('protectVacation.select.noOption')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</ng-container>
