import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Color } from '@shared/models/common';

export interface RecommendationBarStyles {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  width?: string;
  height?: string;
}

export interface RecommendationBarSettings {
  textColor: Color;
  backgroundColor: Color;
  textKey: string;
  textValue?: string;
  styles?: RecommendationBarStyles;
}

@Component({
  selector: 'app-recommendation-bar-v2',
  templateUrl: './recommendation-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class RecommendationBarV2Component implements RecommendationBarSettings {
  @Input() textKey!: string
  @Input() textValue?: string
  @HostBinding('style') styles: Record<string, string> = {
    position: 'absolute',
    height: '1rem',
    top: '0',
    width: '100%',
    ['z-index']: '1',
  }

  _backgroundColor!: Color;
  @Input() set backgroundColor(value: Color) {
    this._backgroundColor = value;
    this._setBackgroundColorClass(value);
  }
  get backgroundColor(): Color {
    return this._backgroundColor;
  }

  _textColor!: Color;
  @Input() set textColor(value: Color) {
    this._textColor = value;
    this._setTextColorClass(value);
  }

  get textColor(): Color {
    return this._textColor;
  }

  @Input() set settings({ textKey, backgroundColor, textColor, textValue, styles }: RecommendationBarSettings) {
    this.textColor = textColor;
    this.backgroundColor = backgroundColor;
    this.textKey = textKey;
    this.textValue = textValue;
    if (styles) {
      Object.keys(styles || {}).forEach(style =>
        this.styles[style] = styles[style as keyof RecommendationBarStyles] || this.styles[style]);
    }
  }

  private readonly _backgroundColorClassPrefix = 'bc-';
  private readonly _textColorClassPrefix = 'color-';
  backgroundColorClass!: string;
  textColorClass!: string;

  private _setBackgroundColorClass(value?: Color) {
    this.backgroundColorClass = this._backgroundColorClassPrefix + (value || 'default');
  }

  private _setTextColorClass(value?: Color) {
    this.textColorClass = this._textColorClassPrefix + (value || 'textPrimary');
  }
}
