import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AddOnPricingType as AddOnPricingResponseType, AddOnResponse } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnToPurchaseViewModel, AddonType, Size, UnavailabilityReason } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';

@Component({
  selector: 'app-addon-pricing',
  templateUrl: './addon-pricing.component.html',
  styleUrls: ['./addon-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AddonPricingComponent implements OnChanges {
  @Input() size: Size = 'default';
  @Input() currentAddOn!: AddOnResponse;
  @Input() addOnsToPurchase!: AddOnToPurchaseViewModel[];
  @Input() unavailabilityReason?: UnavailabilityReason;
  @Output() countToPurchaseChanged = new EventEmitter<number>();
  @Output() ageGroupsSidebarChanged = new EventEmitter();
  price?: number;
  countToPurchase!: number;
  addOnType?: AddOnPricingResponseType;
  maxCountAddonsToPurchase = maxCountAddonsToPurchase;
  isAgeGroupsAddOn = false;

  discountedPrice?: number;

  constructor(
    private readonly _addon: AddOnService
  ) {
  }

  ngOnChanges(): void {
    const addOnId = this.currentAddOn.addOnId;
    const { price = undefined, type, prices = [] } = this.currentAddOn.pricingModel || {};
    this.price = price;
    this.addOnType = type;
    this.isAgeGroupsAddOn = !!prices.length;

    if (!this.addOnsToPurchase?.length) {
      this.addOnsToPurchase = this.addOnsToPurchase || this._addon.getToPurchaseViewModels(addOnId);
    }

    this.countToPurchase = this.addOnsToPurchase.map(item => item.quantity ?? 0).reduce((a, b) => a + b, 0) || 0;
    this.discountedPrice = this._addon.getDiscountedPrice({ addOnId }, this.countToPurchase);
  }

  increase() {
    if (this.countToPurchase < this.maxCountAddonsToPurchase && !this.unavailabilityReason) {
      this.countToPurchase++;
      this.countToPurchaseChanged.emit(this.countToPurchase);
    }
  }

  decrease() {
    if (this.countToPurchase > 0 && !this.unavailabilityReason) {
      this.countToPurchase--;
      this.countToPurchaseChanged.emit(this.countToPurchase);
    }
  }

  change() {
    this.ageGroupsSidebarChanged.emit();
  }
}
