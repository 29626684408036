<ng-container *transloco="let t">
  <div class="flex-column margin-top"
    [class.w-100]="fullWidth"
    [class.padding-horizontal]="fullWidth">
    <app-notification-prices></app-notification-prices>
    <div class="flex-start gap-quarter text-1-1-500 margin-top">
      <app-icon
        name="resort"
        class="margin-top-quarter"
        stroked="transparent"
        filled="textPrimary">
      </app-icon>
      {{resort}} - {{roomType}}
    </div>
    <div class="flex-between text-0_875-0_875-500">
      {{paymentPolicyName}}
    </div>
    <div class="divider-dotted"></div>
    @if(departure && arrival){
    <div class="flex-start gap-quarter text-1-1-500">
      <app-icon
        name="airplane"
        filled="transparent"
        stroked="textPrimary">
      </app-icon>
      {{t('paymentSection.flightsFromTo', { from: departure, to: arrival })}}
    </div>
    <div class="divider-dotted"></div>
    }
    <div class="block-header">
      <div class="block-header-text">
        {{t('paymentSection.total')}}
      </div>
      <div class="block-header-navigation fw-600">
        {{total | appCurrency}}
      </div>
    </div>
    <div class="flex-between">
      <div class="text-0_625-1_125-500 color-formControl">
        ({{t('cart.subheader.tax')}})
      </div>
    </div>
    <div class="flex-between margin-top-quarter">
      <div class="text-0_625-1_125-500">
        {{t('cart.subheader.total')}}
      </div>
    </div>
  </div>
</ng-container>
