import { EventEmitter } from '@angular/core'
import { arrayToRecord } from '@shared/common';

const paymentSectionsArray = ['hotel', 'flight', 'result'] as const;
export type PaymentSection = typeof paymentSectionsArray[number];
export const paymentSections = arrayToRecord(paymentSectionsArray);

export type PaymentEvent = { error?: string, canRetry?: boolean };
export type PaymentEvents = Record<PaymentSection, EventEmitter<PaymentEvent>>;

export const paymentEvents: PaymentEvents = {
  hotel: new EventEmitter<PaymentEvent>(),
  flight: new EventEmitter<PaymentEvent>(),
  result: new EventEmitter<PaymentEvent>(),
};

export interface PaymentResultMessage {
  result: boolean
  amount?: number
  flightsIncluded?: boolean
  recordLocator?: string
  bookingId?: string
  message: string;
  status: 'ready' | 'unavailable' | 'error' | 'success'
  section: PaymentSection
}
