import { Component, ViewChildren } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';
import { arrayFrom } from '@shared/common';
import { CountrySelectOption } from '@shared/models/address';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { SearchService } from '@shared/services/search/search.service';
import { GuestDetailsComponent } from '../guest-details/guest-details.component';
import { FormComponent } from '@shared/components/common/form/form.component';
import { GuestField } from '@booking/models/guest.model';

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  standalone: false
})
export class GuestsComponent extends ReservationVerificationSectionBase<GuestField> {
  @ViewChildren('adultDetails') adultDetails!: GuestDetailsComponent[];
  @ViewChildren('childDetails') childDetails!: GuestDetailsComponent[];

  adults: number[] = [];
  children: number[] = [];
  customerNotes = this._booking.cartRequest?.customerNotes || '';

  get anchorId() {
    return this.adultDetails.find(component => !component.guestForm.valid)?.topErrorAnchorId
      || this.childDetails.find(component => !component.guestForm.valid)?.topErrorAnchorId;
  }

  get isValid() {
    return !this.anchorId;
  }

  get guests() {
    return [...this.adultDetails, ...this.childDetails];
  }

  constructor(
    private readonly _search: SearchService,
    booking: BookingService,
    services: ComponentBaseService
  ) {
    super('guestDetails', booking, services);
    super.pushSub(
      this._search.setWhenContextChanged(() => {
        this.adults = arrayFrom(this._search.getTotalAdults(), (index) => index + 1).filter(item => item !== 1);
        this.children = arrayFrom(this._search.getTotalChildren(), (index) => index + 1);
      })
    );

    this.executeTest(() => this.testEnterAll());
  }

  mainAdultCountryUpdated(newValue: CountrySelectOption) {
    this.adultDetails.forEach(details => details.setDefaultCountry(newValue));
    this.childDetails?.forEach(details => details.setDefaultCountry(newValue));
  }

  testEnterAll() {
    if (this._search.isInitialized) {
      this.adultDetails?.forEach(details => details.testEnterDetails());
      this.childDetails?.forEach(details => details.testEnterDetails());
    } else {
      this.executeTest(() => this.testEnterAll());
    }
  }

  updateReservation() {
    this._updateReservation();
    this._booking.saveCartRequest();
  }

  private _updateReservation() {
    this._booking.reservationInput.customerNotes = this.customerNotes || undefined;
  }

  //#region base
  protected override verificationStarted() {
    this._updateReservation();
    this.adultDetails.forEach(details => details.startVerification());
    this.childDetails?.forEach(details => details.startVerification());
  }

  protected override getFormsComponents(): FormComponent<GuestField>[] {
    return this.guests.map(item => item.fc);
  }

  protected override afterFormValueChanged(index: number) {
    this._updateReservation();
    this.guests[index].updateReservation();
  }
  //#endregion
}
