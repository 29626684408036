import { Component, HostBinding, Input } from '@angular/core';
import { BookingService } from '@booking/services/booking.service';
import { PackageService } from '@shared/services/package.service';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  standalone: false
})
export class PaymentDetailsComponent {
  constructor(
    private readonly _package: PackageService,
    private readonly _booking: BookingService,
  ) {
  }

  @Input() fullWidth = false
  @Input() @HostBinding('class.flex-1') flex1 = true;
  @HostBinding('class.padding') padding = true;

  total = this._booking.reservationInput.scheduleItems.find(() => true)?.amount;

  resort = this._package.resort?.caption;
  roomType = this._package.roomType?.name;

  paymentPolicyName = this._booking.reservationInput.activePolicy?.name;

  departure = this._package.departureAirport?.city;
  arrival = this._package.arrivalAirport?.city;
}
