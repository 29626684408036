import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DateTime } from 'luxon';

import { Airport, Package, RatePlanType, Resort, RoomType } from '@shared/api/be-api.generated';
import { SearchService } from '@shared/services/search/search.service';
import { DataService } from '@shared/services/data.service';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { airportsVisibilityChange, searchBarCollapsedChange, searchBarAirportChange } from '@shared/consts/animations';
import { WithId } from '@shared/models/common';
import { PackageService } from '@shared/services/package.service';
import { TenantService } from '@shared/services/tenant.service';
import { AirportSelectComponent } from '@search/components/flight/airport-select/airport-select.component';
import { maxDepartureDate, minArrivalDate } from '@shared/common';

//type DateRangeType = { start: DateTime, end: DateTime };

@Component({
  selector: 'app-search-bar-v2',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  animations: [airportsVisibilityChange, searchBarCollapsedChange, searchBarAirportChange],
  standalone: false
})
export class SearchBarV2Component extends ComponentBase {
  @ViewChild('departureAirportSelect') departureAirportSelect?: AirportSelectComponent;
  dateRange!: FormGroup<{
    start: FormControl<DateTime | null | undefined>;
    end: FormControl<DateTime | null | undefined>;
  }>;
  departureAirports: Airport[] = [];
  arrivalAirports: Airport[] = [];
  departureAirport?: Airport;
  arrivalAirport?: Airport;

  ratePlanType?: RatePlanType;

  isAnimateDatePicker = false;

  resorts: Resort[] = [];
  resort?: Resort;

  isFlightsIncluded = false;

  ratePlanWithFlights?: RatePlanType;
  ratePlanWithoutFlights?: RatePlanType;

  customBackground = 'acde1ce6-cf48-4569-af00-64848cd58d78';
  resortsWithCustomBackground = ['acde1ce6-cf48-4569-af00-64848cd58d78'];

  backgroundColor = '';

  isCollapsed = false;
  isOpenedShortView = false;
  isShortViewClass = false;

  roomType?: RoomType;
  selectedPackage?: WithId<Package>;
  priceFormat = this._tenant.priceFormat.main;

  minDate = minArrivalDate;
  maxDate = maxDepartureDate;

  isSearchButtonActive = false;

  isLoading = false;

  constructor(
    private readonly _package: PackageService,
    private readonly _search: SearchService,
    private readonly _tenant: TenantService,
    private readonly _data: DataService,

    componentsCommunication: ComponentsCommunicationService,
    services: ComponentBaseService,
  ) {
    super(services);
    super.pushSub(
      _search.loading$.subscribe(isLoading => this.isLoading = isLoading),
      _package.setWhenDataChanged(({ selectedPackage, roomType, resort, ratePlanType, arrivalAirport, departureAirport, fromDate, toDate }) => {
        this.selectedPackage = selectedPackage;
        this.roomType = roomType;
        this.resort = resort;
        this.backgroundColor = this.resortsWithCustomBackground.includes(resort?.resortId || '') ? this.customBackground : '';
        this.ratePlanType = ratePlanType;

        const start = new FormControl(fromDate);
        const end = new FormControl(toDate);
        this.dateRange = new FormGroup({ start, end });
        this.arrivalAirports = resort?.airports || [];
        this.departureAirport = departureAirport;
        this.arrivalAirport = arrivalAirport || this.arrivalAirports?.find(() => true);

        if (this._data.flightRatePlanType) {
          const arriavalAirportsCodes = this.arrivalAirports.map(airport => airport.code);
          this.departureAirports = this._data.values.airports.filter(airport => !arriavalAirportsCodes.includes(airport.code));
        }

        this.isFlightsIncluded = !!this.ratePlanType?.flightsIncluded;
        this.isSearchButtonActive = false;
      }),
      this._data.setWhenInitialized(({ resorts, ratePlanTypes }) => {
        this.resorts = resorts.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        this.ratePlanWithFlights = ratePlanTypes?.find(item => item.flightsIncluded);
        this.ratePlanWithoutFlights = ratePlanTypes?.find(item => !item.flightsIncluded);
      }),
      componentsCommunication.flightsEnabled.subscribe(() => {
        this.isFlightsIncluded = false;
        this.toggleIsFlightsIncluded();
      }),
    );
  }

  onAirportSelect(airport?: Airport, isDepartureAirport?: boolean) {
    if (!airport) {
      this.isFlightsIncluded = false;
    }
    else if (isDepartureAirport) {
      this.departureAirport = airport;
      // search if change rate plan type
      if (!this._package.ratePlanType?.flightsIncluded) {
        this.apply();
      }
      else {
        this.isSearchButtonActive = true;
      }
    } else {
      this.arrivalAirport = airport;
      this.apply();
    }
  }

  compareResort(r1: Resort, r2: Resort): boolean {
    return r1.resortId === r2.resortId;
  }

  resortChanged(resort: Resort) {
    this.resort = resort;
    this.isSearchButtonActive = true;
  }

  dateRangePickerClosed() {
    this.isSearchButtonActive = true;
  }

  toggleIsFlightsIncluded() {
    this.isFlightsIncluded = !this.isFlightsIncluded;
    this.ratePlanType = this.isFlightsIncluded ? this.ratePlanWithFlights : this.ratePlanWithoutFlights;

    if (this.isFlightsIncluded) {
      setTimeout(() => this.departureAirportSelect?.openAutocomplete());
    } else {
      this.apply();
    }
  }

  apply() {
    if (this.isFlightsIncluded && !this.departureAirport) {
      this.toggleIsFlightsIncluded();
    }

    // if we haven't same arrival airport for new resort
    if (!this.resort?.airports?.some(item => item.code === this.arrivalAirport?.code)) {
      this.arrivalAirport = this.resort?.airports?.find(() => true);
    }

    const { start, end } = this.dateRange.value;
    if (this.ratePlanType && this.resort && this.ratePlanType && start && end) {
      this._search.applySearch({
        toDate: end,
        fromDate: start,
        resort: this.resort,
        ratePlanType: this.ratePlanType,
        departureAirport: this.isFlightsIncluded ? this.departureAirport : undefined,
        arrivalAirport: this.isFlightsIncluded ? this.arrivalAirport : undefined
      });
    }
  }

  toggleCollapse() {
    if (!this.isCollapsed) {
      this.isCollapsed = !this.isCollapsed;
      setTimeout(() => {
        this.isOpenedShortView = !this.isOpenedShortView;
        this.isShortViewClass = !this.isShortViewClass;
      }, 400);
    } else {
      this.isOpenedShortView = !this.isOpenedShortView;
      setTimeout(() => {
        this.isCollapsed = !this.isCollapsed;
        this.isShortViewClass = !this.isShortViewClass;
      }, 500);
    }
  }
}
