import { debounceTime } from 'rxjs';
import { Validators } from '@angular/forms';
import { Component, Input, ViewChild } from '@angular/core';

import { AnchorType } from '@shared/models/common';
import { BookingService } from '@booking/services/booking.service';
import { createFormControl, createFormGroup } from '@shared/common.material';
import { FormComponent } from '@shared/components/common/form/form.component';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { cardValidator } from '@shared/components/common/form/credit-card.common';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { ReservationVerificationSectionBase } from '@shared/base/reservation-verification-section.base';

import { CreditCardComponent, CreditCardInfo } from '../credit-card/credit-card.component';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  standalone: false
})
export class PaymentFormComponent extends ReservationVerificationSectionBase<CreditCardInfo> {
  @ViewChild('fc') fc!: FormComponent<CreditCardInfo>;
  @ViewChild('creditCard') creditCard!: CreditCardComponent;

  card = this.booking.reservationInput?.card;
  @Input() full = true;

  form = createFormGroup<CreditCardInfo>({
    cardNumber: createFormControl(this.card?.cardNumber,
      control => cardValidator.number(control.value).isValid ? null : ({ 'invalidCardNumber': true })),
    expirationDate: createFormControl(this.card?.expirationDate,
      control => cardValidator.expirationDate(control.value).isValid ? null : ({ 'invalidExpirationDate': true })),
    securityCode: createFormControl(this.card?.securityCode,
      control => cardValidator.cvv(control.value).isValid ? null : ({ 'invalidSecurityCode': true })),
    name: createFormControl(this.card?.name, Validators.minLength(3)),
    address: createFormControl(this.card?.address, Validators.minLength(3)),
    postalCode: createFormControl(this.card?.postalCode, Validators.minLength(3)),
  });

  isTwoLineCard = this.services.navigation.isTwoLineCard;

  scope = 'creditCard';
  anchorType: AnchorType = 'creditCard';

  get isValid() {
    return this.form.valid;
  }

  get anchorId() {
    const topInvalidField = this.fc?.getTopInvalidField();
    if (topInvalidField) {
      return this.getAnchorId(this.anchorType, topInvalidField);
    }

    return undefined;
  }

  constructor(
    private readonly booking: BookingService,
    comm: ComponentsCommunicationService,
    services: ComponentBaseService
  ) {
    super('creditCard', booking, services);

    this.pushSub(
      comm.mainAdultChanged
        .pipe(debounceTime(500))
        .subscribe(({ controls: { firstName, middleName, lastName, street, postcode } }) => {
          this._enterData({
            name: [firstName?.value, middleName?.value, lastName?.value]
              .filter(item => item).join(' '),
            address: [
              street?.value,
              //city?.value, state?.value, booking.getCountryByCode(country?.value)?.name
            ]
              .filter(item => item).join(', '),
            postalCode: postcode?.value
          });
        })
    );

    this.executeTest(() => this._enterData({
      cardNumber: '4321 0000 0000 1119',
      //address: '89 Main Street',
      expirationDate: '12/31',
      //name: 'Robert Carrusco',
      //postalCode: '89000',
      securityCode: '123'
    }));
  }

  private _enterData(data: Partial<Record<CreditCardInfo, string>>) {
    Object.keys(data).forEach(key =>
      this.fc.set[key as CreditCardInfo](data[key as CreditCardInfo]));
    this.fc.updateWatched();
  }

  public updateReservation() {
    this.verificationStarted();
  }

  protected override afterViewInit() {
    if (this.card) {
      this.fc.updateWatched();
    }
  }

  protected override verificationStarted() {
    this.form.markAllAsTouched();

    const cardNumber = this.fc.get.cardNumber<string>()?.split(' ').join('');
    const securityCode = this.fc.get.securityCode<string>()?.split(' ').join('');
    const expirationDate = this.fc.get.expirationDate<string>();

    this.booking.reservationInput.setCardInfo({
      cardNumber, securityCode, expirationDate,
      address: this.fc.get.address<string>(),
      name: this.fc.get.name<string>(),
      postalCode: this.fc.get.postalCode<string>(),
    });
  }
}
