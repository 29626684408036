<ng-container *transloco="let t">
  <div class="payment-stepper">
    <mat-stepper #stepper
      [linear]="false"
      [(selectedIndex)]="selectedIndex"
      [labelPosition]="(stepperLabelOrientation | async)!">
      @for (section of availableSections; track $index) {
      <mat-step
        [ngClass]="section"
        [aria-label]="state[section]"
        [completed]="$index < selectedIndex"
        [editable]="false">
        <ng-template matStepLabel>
          <div class="not-selectable flex-between">
            <span
              class="fw-500 break-spaces"
              [class.color-formControl]="selectedIndex !== $index"
              [class.color-textSuccessPrice]="isSuccess[section]"
              [class.color-textError]="error[section]">
              {{t('paymentSection.' + section)}}
            </span>
            @if(isLoading[section]){
            <app-loading-icon
              size="button">
            </app-loading-icon>
            }
            @else if(isSuccess[section]){
            <app-icon
              class="checked-success"
              size="default"
              name="checked">
            </app-icon>
            }
            @else if(error[section]){
            <app-icon
              stroked="textError"
              size="default"
              name="close">
            </app-icon>
            }
          </div>
        </ng-template>

        <ng-template matStepContent>
          <div class="payment-stepper-content bc-default">
            @if (error[section]) {
            <div class="color-required margin-bottom">
              {{error[section]}}
            </div>
            @if (section === 'hotel'){
            <app-payment-form
              #paymentForm>
            </app-payment-form>
            <div class="flex margin-top-double">
              <app-icon-button
                name="lock"
                alt="reviewBooking.agreeButton"
                filled="default"
                [customButtonSize]="true"
                (click)="restrartPayment()"
                [transparent]="false"
                [buttonClass]="'select-button active w-100'"
                class="w-100"
                type="raised">
                {{t('paymentSection.reTry')}}
              </app-icon-button>
            </div>
            }
            }
            @if (isLoading[section]) {
            <div class="flex-column gap"
              [innerHTML]="t('paymentSection.loading.'+ section)">
            </div>
            }

            @if (isSuccess[section]){
            <div class="flex-start margin-bottom">
              <app-icon
                class="checked-success"
                size="big"
                name="checked">
              </app-icon>
              <div class="text-1_25-1_25-600 color-textSuccessPrice"
                [innerHTML]="t('paymentSection.successHeader.'
                + (error['flight'] ? 'flightFailed' : 'all'))">
              </div>
            </div>
            <div class="flex-column margin-bottom"
              [innerHTML]="t('paymentSection.success.'
                + (error['flight'] ? 'flightFailed' : 'all'))">
            </div>
            <div [innerHTML]="t('paymentSection.successFooter', { email: email })">
            </div>
            @if (section === 'result'){
            <div class="flex-end margin-top-double">
              <button mat-raised-button
                (click)="openConfirmationPage()"
                class="select-button"
                color="primary">
                {{t('paymentSection.openConfirmation')}}
              </button>
            </div>
            }
            }

            <!-- <app-loading-screen
              class="margin-top-double"
              [section]="loaderSection"
              [isFullScreen]="false">
            </app-loading-screen> -->
          </div>
        </ng-template>
      </mat-step>
      }
    </mat-stepper>
  </div>
</ng-container>