import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ComponentBase } from '@shared/base/component.base';
import { ComponentBaseService } from '@shared/services/component-base.service';
import { MatStepper } from '@angular/material/stepper';
import { Package, RoomType } from '@shared/api/be-api.generated';
import { PackageService } from '@shared/services/package.service';
import { ButtonSize, WithId, minWidth, screenWidths } from '@shared/models/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { stepperNextButtonChange } from '@shared/consts/animations';
import { PricingDetailsConfig } from '@search/components-v2/shared/pricing-details/pricing-details.component';
import { ComponentsCommunicationService } from '@shared/services/components-communication.service';
import { MemberService } from '@member/member.service';
import { displayNumbersInsteadOfIcons, StepperLabelOrientation } from '@shared/common.material';

const _searchSections = ['packages', 'flights', 'addons', 'transfer', 'review'] as const;
type SearchSection = typeof _searchSections[number];

export const searchSections = _searchSections.reduce((all, current) =>
  ({ ...all, [current]: current }),
  {} as Record<SearchSection, SearchSection>);

const { packages, flights, addons, transfer, review } = searchSections;

@Component({
  selector: 'app-search-stepper-v2',
  templateUrl: './search-stepper.component.html',
  styleUrls: ['./search-stepper.component.scss'],
  animations: [stepperNextButtonChange],
  standalone: false
})
export class SearchStepperV2Component extends ComponentBase implements AfterViewInit {
  constructor(
    private readonly _member: MemberService,
    _package: PackageService,
    services: ComponentBaseService,
    comm: ComponentsCommunicationService,
    breakpointObserver: BreakpointObserver,
  ) {
    super(services);
    this.pushSub(
      comm.openFlightsSection.subscribe(() => this.setActiveSection(flights)),
      _member.onMemberChanged(() => this.profile = _member.profile),
      _package.setWhenDataChanged(({ roomType, selectedPackage, departureAirport, airportTransfers, resort }) => {
        this.roomType = roomType;
        this.selectedPackage = selectedPackage;
        if (roomType?.roomTypeId) {
          this.pricingConfig = new PricingDetailsConfig(roomType.roomTypeId);
          this.pricingConfig.disabled = !selectedPackage;
          this.pricingConfig.hideButton = true;
        }

        const oldIsFlights = this.isFlights;
        this.isFlights = !!departureAirport
        this.availableSections = [packages];

        if (departureAirport) {
          this.availableSections.push(flights);
        }

        const isTransfers = (this.isFlights && airportTransfers?.length) || resort?.airportTransfers?.length;
        this.availableSections.push(isTransfers ? transfer : addons);

        if (this.selectedPackage) {
          this.availableSections.push(review);
        }

        // fallback to default page for smooth transition
        if (!this.isFlights && oldIsFlights !== this.isFlights) {
          this.setActiveSection(packages);
        }
      })
    );

    const sectionFromQuery = (this.isTest && this.hash as SearchSection);
    this.setActiveSection(sectionFromQuery || packages);
    this._setWidthsObservers(breakpointObserver);
  }

  @ViewChild(MatStepper) stepper!: MatStepper;
  allSection = searchSections;
  availableSections: SearchSection[] = [];
  currentSection!: SearchSection;
  selectedIndex = 0;

  isFlights = false;
  roomType?: RoomType;
  selectedPackage?: WithId<Package>;
  priceFormat = this.services.tenant.priceFormat.main;
  pricingConfig!: PricingDetailsConfig;
  profile = this._member.profile;

  sectionIcons: Partial<Record<SearchSection, string>> = {
    [packages]: 'resort', [flights]: 'airplane', [addons]: 'info', [transfer]: 'arrival', [review]: 'checkmark'
  };

  pricingVisible: Partial<Record<SearchSection, boolean>> = {
    [packages]: true, [flights]: true, [addons]: true, [transfer]: true, [review]: false
  };

  stepperLabelOrientation!: Observable<StepperLabelOrientation>;
  pricingSize!: Observable<ButtonSize>;

  sectionSettings: Partial<Record<SearchSection, {
    number: number,
    header: string,
    next?: SearchSection,
    previous?: SearchSection,
    marginLessBottom?: boolean
  }>> = {}

  ngAfterViewInit(): void {
    displayNumbersInsteadOfIcons(this.stepper);
  }

  setActiveSection(section: SearchSection) {
    const newIndex = this.availableSections.findIndex(item => item == section);
    if (newIndex > -1) {
      this.selectedIndex = newIndex;
    }
    this.currentSection = section;
    this.setSectionSettings();
  }

  setSectionSettings() {
    this.sectionSettings = {
      packages: {
        number: 1,
        header: 'room.header',
        next: this.isFlights ? flights : addons,
        previous: undefined
      },
      addons: {
        number: 2,
        header: 'addOn.header',
        next: this.selectedPackage ? review : undefined,
        previous: packages
      },
      flights: {
        number: 2,
        header: 'flight.confirm.header',
        next: transfer,
        previous: packages
      },
      transfer: {
        number: 3,
        header: 'airportTransfer.header',
        next: this.selectedPackage ? review : undefined,
        previous: this.isFlights ? flights : packages
      },
      review: {
        number: 4,
        header: 'cart.header.breakdown.' + this.page,
        previous: this.isFlights ? transfer : addons,
        marginLessBottom: true
      }
    };
  }

  private _setWidthsObservers(breakpointObserver: BreakpointObserver) {
    this.stepperLabelOrientation = breakpointObserver
      .observe(minWidth(screenWidths.tablet, 150))
      .pipe(map<BreakpointState, StepperLabelOrientation>(({ matches }) => (matches ? 'end' : 'bottom')));

    this.pricingSize = breakpointObserver
      .observe(minWidth(screenWidths.tablet, 150))
      .pipe(map<BreakpointState, ButtonSize>(({ matches }) => (matches ? 'default' : 'big')));
  }
}
