import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SidebarService } from '@shared/services/sidebar/sidebar.service';


@Component({
  selector: 'app-member-option-banner-v2',
  templateUrl: './member-option-banner-v2.component.html',
  styleUrls: ['./member-option-banner-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MemberOptionBannerV2Component {
  constructor(
    private readonly _sidebar: SidebarService
  ) {

  }

  open() {
    this._sidebar.openMember();
  }
}
