import { arrayFrom } from '@shared/common';
import { SelectOption } from '@shared/models/common';
import { DateTime } from 'luxon';

export const guestFields = [
  'firstName', 'middleName', 'lastName',
  'year', 'month', 'day',
  'phone', 'country',
  'address', 'street', 'city', 'state', 'postcode',
  'gender'
] as const;

export type GuestField = typeof guestFields[number];

export const currentYear = DateTime.now().year;

export type DobOptions = Record<string, SelectOption[]>;
export const monthes = arrayFrom(12, index => ({
  label: DateTime.local(currentYear, index + 1, 1).toFormat('MMMM'),
  value: String(index + 1).padStart(2, '0')
}))
