import { AfterViewInit, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';


import { BookingService } from '@booking/services/booking.service';
import { ComponentBaseService } from '@shared/services/component-base.service';

import { ComponentBase } from '@shared/base/component.base';
import { Color, LoaderSection } from '@shared/models/common';
import { PaymentEvents, PaymentSection, paymentSections } from '@booking/models/payment.model';
import { paymentFormVisibility } from '@shared/consts/animations';
import { PaymentFormComponent } from '../payment-form/payment-form.component';

@Component({
  selector: 'app-payment-logger',
  templateUrl: './payment-logger.component.html',
  standalone: false,
  animations: [paymentFormVisibility.enter, paymentFormVisibility.leave]
})
export class PaymentLoggerComponent extends ComponentBase implements AfterViewInit {
  constructor(
    private readonly _booking: BookingService,
    services: ComponentBaseService,
  ) {
    super(services);

  }

  @Input() events!: PaymentEvents;
  @HostBinding('class.flex-1') flex1 = true;
  @ViewChild('paymentForm') paymentForm?: PaymentFormComponent;
  @Output() restartPayment = new EventEmitter();
  @Output() openConfirmation = new EventEmitter();

  isFlight = !!(this._booking.lastRequest || this._booking.nextRequest)?.flightsToBuy?.itinerary;
  email = this._booking.reservationInput.loyalty?.email;
  allSections = paymentSections;
  loaderSection: LoaderSection = 'paymentHotel';
  needRetry = false;
  attempt = 1;

  error: Partial<Record<PaymentSection, string>> = {}

  log: {
    key?: string,
    text?: string,
    color?: Color,
    data?: Record<string, string>,
    isFinished?: boolean
  }[] = [
      { key: 'loading.hotel', color: 'selected' }
    ];

  ngAfterViewInit(): void {
    this.subscribeToPaymentEvents();
  }

  subscribeToPaymentEvents() {
    this.pushSub(
      this.events.hotel.subscribe(({ error, canRetry }) => {
        if (error) {
          this.log.push({ key: 'error.hotel', color: 'textError', data: { error } });
          window.setTimeout(() => this.needRetry = canRetry || false, 1000);
        }
        else {
          this.needRetry = false;
          this.log.push({ key: 'success.hotel', color: 'textSuccessPrice' });

          if (this.isFlight) {
            this.log.push({ key: 'loading.flight', color: 'selected' });
          } else {
            this.log.push({ key: 'success.all', color: 'textSuccessPrice' });
          }

          this.addSuccessFooter();
        }
      }),

      this.events.flight.subscribe(({ error }) => {
        if (error) {
          this.log.push({ text: error, color: 'textError' });
          this.log.push({ key: 'success.flightFailed', color: 'textSuccessPrice' });
        }
        else {
          this.log.push({ key: 'success.flight', color: 'textSuccessPrice' });
          this.log.push({ key: 'success.all', color: 'textSuccessPrice' });
        }

        this.addSuccessFooter();
      })
    );
  }

  addSuccessFooter() {
    window.setTimeout(() => {
      this.log.push({ key: 'successFooter', data: { 'email': this.email || 'your email' }, isFinished: true });
    }, 1000);
  }

  restrartPayment() {
    this.attempt++;
    this.needRetry = false;
    this.log.push({ key: 'loading.reTryHotel', color: 'selected', data: { 'attempt': this.attempt.toString() } })
    this.paymentForm?.updateReservation();
    this.restartPayment.emit();

    // window.setTimeout(() => {
    //   this.events.hotel.emit();

    //   if (this.isFlight) {
    //     window.setTimeout(() => {
    //       this.events.flight.emit();
    //     }, 5000);
    //   }
    // }, 2000);
  }

  openConfirmationPage() {
    this.openConfirmation.emit();
  }
}
