import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AddOnResponse } from '@shared/api/be-api.generated';
import { maxCountAddonsToPurchase } from '@shared/consts/common';
import { AddOnAgeGroupQuantityChange, AddOnToPurchaseViewModel, AgeGroupViewModel, UnavailabilityReason } from '@shared/models/common';
import { AddOnService } from '@shared/services/add-on.service';

@Component({
  selector: 'app-addon-age-groups-pricing',
  templateUrl: './addon-age-groups-pricing.component.html',
  styleUrls: ['./addon-age-groups-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AddonAgeGroupsPricingComponent implements OnChanges {
  @Input() currentAddOn!: AddOnResponse;
  @Input() unavailabilityReason?: UnavailabilityReason;
  @Output() countToPurchaseChanged = new EventEmitter<AddOnAgeGroupQuantityChange>();

  @Input() addOnsToPurchase!: AddOnToPurchaseViewModel[];
  ageGroups: AgeGroupViewModel[] = [];
  maxCountToPurchase = maxCountAddonsToPurchase;

  constructor(
    private readonly _addon: AddOnService
  ) {
  }

  ngOnChanges(): void {
    const addOnId = this.currentAddOn.addOnId;

    const newAgeGroups = (this.currentAddOn.pricingModel?.prices || [])
      .map(item => {
        const ageGroupId = item.id;
        const quantity = this.addOnsToPurchase.filter(addOn => addOn.ageGroupId === ageGroupId)
          .reduce((value, item) => value += (item.quantity || 0), 0); //total quanity purchased
        const from = item.agePeriod?.fromYear || 0;
        const to = item.agePeriod?.toYear || 0;
        const discountedPrice = this._addon.getDiscountedPrice({ addOnId, ageGroupId }, quantity);

        return new AgeGroupViewModel({ ...item, from, to, quantity, discountedPrice });
      });

    this.ageGroups.length !== newAgeGroups.length
      ? this.ageGroups = newAgeGroups // initial setup
      : this.ageGroups.forEach((ageGroup, index) => // update
      {
        ageGroup.quantity = newAgeGroups[index].quantity;
        ageGroup.discountedPrice = newAgeGroups[index].discountedPrice;
      });
  }

  increase(item: AgeGroupViewModel) {
    const { id: ageGroupId } = item;
    if (!this.unavailabilityReason && ageGroupId && item.quantity < this.maxCountToPurchase) {
      item.quantity += 1;
      this.countToPurchaseChanged.emit({ ageGroupId, quantity: item.quantity });
    }
  }

  decrease(item: AgeGroupViewModel) {
    const { id: ageGroupId } = item;
    if (!this.unavailabilityReason && ageGroupId && item.quantity) {
      item.quantity -= 1;
      this.countToPurchaseChanged.emit({ ageGroupId, quantity: item.quantity });
    }
  }
}
