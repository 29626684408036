import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

export const createFormControl = (initialState: string | undefined = '', ...validators: ValidatorFn[]) =>
  new UntypedFormControl(initialState, [Validators.required, ...validators]);

export const createFormGroup = <T extends string>(controls: Record<T, FormControl>) =>
  new FormGroup<Record<T, FormControl>>(controls);

export const createUntypedFormGroup = <T extends string>(controls: Record<T, UntypedFormControl | undefined>) => {
  const result: Record<string, UntypedFormControl> = {};

  Object.keys(controls).forEach(key => {
    const controlKey = key as T;
    const control = controls[controlKey];
    if (control && controlKey) {
      result[controlKey] = control;
    }
  });


  return new UntypedFormGroup(result);
}

export const displayNumbersInsteadOfIcons = (stepper: MatStepper) =>
  stepper._getIndicatorType = () => 'number' //https://github.com/angular/components/issues/10513

export type StepperLabelOrientation = 'bottom' | 'end';

