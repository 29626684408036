<div class="two-columns">
  <div class="main-container flex-1"
    [class.reversed]="isReversed">
    <div class="content-container left-column gap"
      [class.margin-bottom-less]="marginBottomLess">
      <ng-template [ngTemplateOutlet]="leftColumn"></ng-template>
    </div>
    <div [ngClass]="['content-container right-column hidden-tablet-max', rightColumnMaxWidth]"
      [class.sticky-column]="isSticky">
      <ng-template [ngTemplateOutlet]="rightColumn"></ng-template>
    </div>
  </div>
</div>