import { Component, ContentChild, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiException, MembershipRegistration } from '@shared/api/be-api.generated';
import { FormComponent } from '@shared/components/common/form/form.component';
import { MemberService } from '@member/member.service';
import { TenantService } from '@shared/services/tenant.service';

@Component({
  selector: 'app-member-register-v2',
  templateUrl: './member-register.component.html',
  standalone: false
})
export class MemberRegisterV2Component {
  constructor(
    private readonly _member: MemberService,
    private readonly _tenant: TenantService
  ) {
  }

  @ViewChild('form') form!: FormComponent<'email' | 'password' | 'firstname' | 'lastname'>;
  @ContentChild('rewardBullets') rewardBullets!: TemplateRef<unknown>;

  formGroup = new FormGroup({
    email: new FormControl<string | null | undefined>(this._tenant.defaultMember?.email, [
      Validators.required, Validators.email
    ]),
    password: new FormControl<string | null | undefined>(this._tenant.defaultMember?.password, [
      Validators.required, Validators.minLength(8), ({ value }) => value && value.indexOf(' ') > - 1 ? { space: true } : null
    ]),
    firstname: new FormControl<string | null | undefined>(undefined, [
      Validators.required
    ]),
    lastname: new FormControl<string | null | undefined>(undefined, [
      Validators.required
    ]),
  });

  isLoading = false;
  isAgree!: boolean;
  errorMessage?: string;
  cendynRegisterEmailErrors = ['UserNameAlreadyExists'];

  toggleAgreementSwitcher() {
    if (!this.isLoading) {
      this.isAgree = !this.isAgree;
    }
  }

  register() {
    if (!this.formGroup.valid || !this.isAgree) {
      return;
    }

    this.isLoading = true;
    this.errorMessage = undefined;

    const email = this.form.get.email<string>().trim();
    const password = this.form.get.password<string>();
    const firstname = this.form.get.firstname<string>();
    const lastname = this.form.get.lastname<string>();
    const newsletterSubscription = this.isAgree;
    const data = new MembershipRegistration({ email, password, firstname, lastname, newsletterSubscription });

    this._member.register(data, (error: ApiException) => {
      this.errorMessage = this._member.getMembershipError(error, {
        'member.register.error.userExists': response =>
          this.cendynRegisterEmailErrors.some(item => response.indexOf(item) > -1)
      });

      this.isLoading = false;
    });
  }
}
