<ng-container *transloco="let t">
  <div class="form">
    <form [formGroup]="form"
      #formElement>
      <ng-container #templates>
        <ng-template #error
          let-field="field"
          let-error="error"
          let-errorKey="errorKey">
          <span [innerHtml]="t([scope, 'error', error] | join, {
            field: [scope, errorKey || field] | translateFormComponentText: 'label',
            value: form.get(field)?.value || ''
          })">
          </span>
        </ng-template>

        <ng-template #caption
          let-field="field"
          let-required="required">
          {{[scope, field] | translateFormComponentText: 'label'}}
          <b class="color-required"
            *ngIf="required || (form?.get(field) | isFormControlRequired)">&nbsp;*</b>
        </ng-template>

        <ng-template #label
          let-field="field"
          let-required="required"
          let-labelKey="labelKey">
          <div class="label"
            *ngIf="form.get(field)?.untouched || !form.get(field)?.invalid">
            <ng-container *ngTemplateOutlet="caption; context: {
              field: labelKey || field,
              required
            }">
            </ng-container>
          </div>
          <div class="label label-error"
            *ngIf="!form.get(field)?.untouched && form.get(field)?.invalid">
            <app-anchor
              *ngIf="anchorType"
              [type]="anchorType"
              [index]="[anchorNumber, field]">
            </app-anchor>
            <mat-error class="error-message bold">
              <ng-container *ngTemplateOutlet="error;
                context: { field, error: getFirstError(field), errorKey: labelKey }">
              </ng-container>
            </mat-error>
          </div>
        </ng-template>

        <ng-template #subLabel
          let-field="field">
          <div class="sub-label"
            [innerHTML]="[scope, field] | translateFormComponentText: 'subLabel'">
          </div>
        </ng-template>

        <ng-template #text
          let-field="field"
          let-type="type"
          let-autocomplete="autocomplete"
          let-isSubLabel="isSubLabel"
          let-required="required"
          let-disabled="disabled"
          let-labelKey="labelKey"
          let-nextFieldId="nextFieldId">

          <ng-container *ngTemplateOutlet="label; context: { field, required, labelKey }"></ng-container>
          <div class="value">
            <mat-form-field appearance="outline"
              [class.textarea]="type === 'textarea'"
              [class.disabled]="disabled">
              @if(type === 'textarea'){
              <textarea matInput
                #input
                [type]="type"
                [name]="field"
                [attr.disabled]="disabled || null"
                [attr.autocomplete]="autocomplete || 'on'"
                [id]="field"
                [placeholder]="[scope, labelKey || field] | translateFormComponentText: 'placeholder'"
                [formControlName]="field">
              </textarea>
              }
              @else {
              <input matInput
                #input
                [type]="type || 'text'"
                [name]="field"
                [attr.inputmode]="inputMode[type || ''][autocomplete || '']"
                [readonly]="disabled"
                [attr.autocomplete]="autocomplete || 'on'"
                [id]="field"
                [placeholder]="[scope, labelKey || field] | translateFormComponentText: 'placeholder'"
                [formControlName]="field" />
              @if(autocomplete === 'cc-number'){
              <img
                (appInit)="watch({ input, nextFieldId, image})"
                class="credit-card margin-less"
                #image
                alt="Card Type"
                [src]="'creditcard' | iconPath: 'payments'">
              }
              @else if(autocomplete === 'cc-exp'){
              <img
                (appInit)="watch({ input, nextFieldId })"
                class="credit-card margin-less"
                [src]="'expirationdate' | iconPath: 'payments'">
              }
              @else if(autocomplete === 'cc-csc'){
              <img
                (appInit)="watch({ input, nextFieldId })"
                class="credit-card margin-less"
                [src]="'securitycode' | iconPath: 'payments'">
              }
              }
            </mat-form-field>
          </div>
          @if(isSubLabel){
          <ng-container *ngTemplateOutlet="subLabel; context: { field }"></ng-container>
          }
        </ng-template>

        <ng-template #creditCard
          let-fields="fields"
          let-isSubLabel="isSubLabel"
          let-required="required"
          let-disabled="disabled"
          let-labelKey="labelKey">
          @if(!form.get(fields.cardNumber)?.untouched && form.get(fields.cardNumber)?.invalid){
          <ng-container *ngTemplateOutlet="label; context: { field: 'cardNumber', required, labelKey }"></ng-container>
          }
          @else if(!form.get(fields.expirationDate)?.untouched && form.get(fields.expirationDate)?.invalid){
          <ng-container *ngTemplateOutlet="label; context: { field: 'expirationDate', required, labelKey }"></ng-container>
          }
          @else if(!form.get(fields.securityCode)?.untouched && form.get(fields.securityCode)?.invalid){
          <ng-container *ngTemplateOutlet="label; context: { field: 'securityCode', required, labelKey }"></ng-container>
          }
          @else{
          <ng-container *ngTemplateOutlet="label; context: { field: 'creditCard', required, labelKey }"></ng-container>
          }
          <div class="value">
            <div class="flex-between input-border-less border-formControl flex-column-desktop-max card-container">
              <mat-form-field appearance="outline"
                [class.disabled]="disabled">
                <div class="flex-start">
                  <img
                    class="credit-card margin-right"
                    [src]="'creditcard' | iconPath: 'payments'"
                    (appInit)="watch({ input: inputCardNumber, nextFieldId: fields.expirationDate, image: creditCardImage })"
                    #creditCardImage>
                  <input matInput
                    #inputCardNumber
                    type="text"
                    [name]="fields.cardNumber"
                    [attr.inputmode]="inputMode['text']['cc-number']"
                    [readonly]="disabled"
                    pattern="[0-9 ]+"
                    autocomplete="cc-number"
                    [id]="fields.cardNumber"
                    [placeholder]="[scope, labelKey || fields.cardNumber] | translateFormComponentText: 'placeholder'"
                    [formControlName]="fields.cardNumber" />
                </div>
              </mat-form-field>

              <div class="flex-between gap w-100">
                <mat-form-field appearance="outline"
                  class="flex-2"
                  [class.disabled]="disabled">
                  <img
                    class="credit-card"
                    (appInit)="watch({ input: inputExpirationDate, nextFieldId: fields.securityCode })"
                    [src]="'expirationdate' | iconPath: 'payments'">
                  <input matInput
                    #inputExpirationDate
                    type="text"
                    [name]="fields.expirationDate"
                    [attr.inputmode]="inputMode['text']['cc-exp']"
                    [readonly]="disabled"
                    autocomplete="cc-exp"
                    [id]="fields.expirationDate"
                    [placeholder]="[scope, labelKey || fields.expirationDate] | translateFormComponentText: 'placeholder'"
                    [formControlName]="fields.expirationDate" />
                </mat-form-field>

                <mat-form-field appearance="outline"
                  class="flex-3"
                  [class.disabled]="disabled">
                  <img
                    class="credit-card filled-formControl"
                    (appInit)="watch({ input: inputSecurityCode })"
                    [src]="'securitycode' | iconPath: 'payments'">
                  <input matInput
                    #inputSecurityCode
                    type="text"
                    [name]="fields.securityCode"
                    [attr.inputmode]="inputMode['number']['cc-csc']"
                    [readonly]="disabled"
                    autocomplete="cc-csc"
                    [id]="fields.securityCode"
                    [placeholder]="[scope, labelKey || fields.securityCode] | translateFormComponentText: 'placeholder'"
                    [formControlName]="fields.securityCode" />
                </mat-form-field>
              </div>
            </div>
            <div class="flex-start-center gap-quarter margin-top-half">
              <app-icon
                filled="formControl"
                name="lock">
              </app-icon>
              <ng-container *ngTemplateOutlet="subLabel; context: {
                field: 'creditCard'
              }"></ng-container>
            </div>

          </div>

          @if(isSubLabel){
          <ng-container *ngTemplateOutlet="subLabel; context: { field: fields.creditCard }"></ng-container>
          }
        </ng-template>

        <ng-template #select
          let-field="field"
          let-options="options"
          let-hideLabel="hideLabel">
          <ng-container *ngIf="!hideLabel">
            <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          </ng-container>
          <div class="value">
            <mat-form-field>
              <mat-select
                #select
                [id]="field"
                [placeholder]="t([scope, field] | join)"
                (focus)="selectFocus(select)"
                [compareWith]="compareWith"
                [formControlName]="field"
                (selectionChange)="selectChange(field, $event.value)">
                <mat-option
                  *ngFor="let option of options; trackBy: 'value' | createTrackBy"
                  [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-template>

        <ng-template #autocomplete
          let-field="field"
          let-options="options"
          let-isLoading="isLoading"
          let-isSeparatedButton="isSeparatedButton">
          <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          <div class="value"
            [class.flex]="isSeparatedButton">
            <mat-form-field appearance="outline">
              <input matInput
                #input
                type="text"
                [id]="field"
                [name]="field"
                autocomplete="on"
                (keydown.enter)="$event.preventDefault(); autocompleteSearch(field)"
                (input)="inputAutocompleteChange(field, input.value)"
                (blur)="isLoading !== undefined ? form.get(field)?.markAsTouched() : (0)"
                [placeholder]="[scope, field] | translateFormComponentText: 'placeholder'"
                [matAutocomplete]="formAutoComplete" />
              <app-icon-button
                name="search"
                type="icon"
                *ngIf="!isSeparatedButton"
                (click)="autocompleteSearch(field)"
                filled="transparent"
                [stroked]="input.value ? 'selected':  'border'"
                [postfix]="true"
                [isLoading]="isLoading"
                [disabled]="!input.value || isLoading"
                [transparent]="true">
              </app-icon-button>
            </mat-form-field>
            <mat-autocomplete
              (closed)="autocompleteClose(field)"
              (optionSelected)="autocompleteSelected(field, $event.option.value)"
              [autoActiveFirstOption]="false"
              [displayWith]="defaultDisplayWith"
              #formAutoComplete="matAutocomplete">
              <mat-option
                *ngFor="let option of options; trackBy: 'value' | createTrackBy"
                [value]="option"
                [disabled]="option.isDisabled">
                {{option.label}}
              </mat-option>
            </mat-autocomplete>
            <app-icon-button
              name="search"
              type="raised"
              *ngIf="isSeparatedButton"
              (click)="autocompleteSearch(field)"
              filled="transparent"
              [stroked]="input.value ? 'selected':  'border'"
              buttonSize="input"
              iconSize="big"
              class="margin-left"
              [postfix]="true"
              [isLoading]="isLoading"
              [disabled]="!input.value || isLoading"
              [transparent]="true">
              <span class="text-1_125-1_5-400"
                [class.color-selected]="input.value"
                [class.color-border]="!input.value">
                {{t('button.search.default')}}
              </span>
            </app-icon-button>
          </div>
        </ng-template>

        <ng-template #datetime
          let-field="field">
          <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>
          <input #timeInputPicker
            #timePicker="matTimepicker"
            matTimepicker
            [okLabel]="t('timePicker.ok')"
            [cancelLabel]="t('timePicker.cancel')"
            [anteMeridiemAbbreviation]="t('timePicker.am')"
            [postMeridiemAbbreviation]="t('timePicker.pm')"
            mode="12h"
            hidden
            (close)="$event.preventDefault()"
            (timeChange)="dateTimeChange(
              field,
              $event,
              datePicker.datepickerInput.getStartValue(),
              dateTimeInput
            )">
          <mat-form-field>
            <input #dateTimeInput
              [id]="field"
              matInput
              readonly
              [placeholder]="[scope, field] | translateFormComponentText: 'placeholder'"
              (keyup.enter)="dateTimeInput.click()"
              (click)="datePicker.opened ? (0) : datePicker.open()">
            <input #dateInput
              [matDatepicker]="datePicker"
              hidden>
            <mat-datepicker-toggle matSuffix [for]="datePicker" [tabIndex]="-1">
              <app-icon matDatepickerToggleIcon
                name="calendar"
                filled="selected">
              </app-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              panelClass="form-date-picker"
              #datePicker
              (closed)="dateTimeChange(
                field,
                timePicker.value,
                datePicker.datepickerInput.getStartValue(),
                dateTimeInput);
                timeInputPicker.click();">
            </mat-datepicker>
          </mat-form-field>
        </ng-template>

        <ng-template #date
          let-field="field"
          let-valueFormat="valueFormat"
          let-viewFormat="viewFormat">
          <ng-container *ngTemplateOutlet="label; context: { field }"></ng-container>

          <mat-form-field>
            <input #dateInputField
              [id]="field"
              matInput
              readonly
              [placeholder]="[scope, field] | translateFormComponentText: 'placeholder'"
              (keyup.enter)="dateInputField.click()"
              (appInit)="initDate(field, form.value[field], dateInputField, viewFormat)"
              (click)="datePicker.opened ? (0) : datePicker.open()">
            <input #dateInput
              [formControlName]="field"
              [matDatepicker]="datePicker"
              hidden>
            <mat-datepicker-toggle matSuffix [for]="datePicker" [tabIndex]="-1">
              <app-icon matDatepickerToggleIcon
                name="calendar"
                filled="selected">
              </app-icon>
            </mat-datepicker-toggle>
            <mat-datepicker
              panelClass="form-date-picker"
              #datePicker
              (closed)="dateChange(
                field,
                datePicker.datepickerInput.getStartValue(),
                dateInputField,
                viewFormat,
                valueFormat);">
            </mat-datepicker>
          </mat-form-field>
        </ng-template>
      </ng-container>

      <ng-template *ngTemplateOutlet="content; context: {
        text,
        select,
        label,
        caption,
        autocomplete,
        datetime,
        date,
        creditCard,
        subLabel
      }">
      </ng-template>
    </form>
  </div>
</ng-container>