import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { AnchorType, FormAutocompleteOptionSelected, SelectOption } from '@shared/models/common';
import { DateTime } from 'luxon';
import { dateFormat, dateTimeFormat, dateTimeStringify, formatDateTime } from 'src/app/formats/date-time';
import { convertToDateTime } from '@shared/api/be-api.generated';
import { IconPathPipe } from 'src/app/pipes/icon-path.pipe';
import { cardValidator, cardCorrector, knownCreditCards } from './credit-card.common';

export type WatchInput = {
  input: HTMLInputElement,
  image?: HTMLImageElement
  nextFieldId?: string
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  standalone: false
})
export class FormComponent<TField extends string> implements OnChanges {
  constructor(
    private readonly _renderer: Renderer2,
    private readonly _pathPipe: IconPathPipe
  ) {
  }

  @Input() form!: UntypedFormGroup;
  @Input() scope!: string;
  @Input() anchorType?: AnchorType;
  @Input() anchorNumber?: string | number;
  @Input() defaultDateFormat = dateFormat.type4;

  @ViewChildren('select') selects!: MatSelect[];
  @ViewChildren('input') inputs!: ElementRef[];

  @Output() selectChanged = new EventEmitter<TField>();
  @Output() nextChanged = new EventEmitter<TField>();

  autocomplete = {
    selected: new EventEmitter<FormAutocompleteOptionSelected<TField, SelectOption>>(),
    search: new EventEmitter<TField>(),
    closed: new EventEmitter<TField>(),
    input: new EventEmitter<TField>(),
  };

  inputMode: Record<string, Record<string, 'text' | 'numeric'>> = {
    'text': {
      'cc-number': 'numeric',
      'cc-exp': 'numeric',
      '': 'text'
    },
    'number': {
      '': 'numeric'
    },
    '': {
      '': 'text'
    }
  };

  @ContentChild('content') content!: TemplateRef<unknown>;
  @ViewChild('formElement') formElementRef!: ElementRef;

  fields!: TField[];
  get!: Record<TField, <TResult>() => TResult>;
  set!: Record<TField, <TValue>(value: TValue) => void>;

  get asObject(): object {
    return this.fields
      .map(field => ({ [field]: this.get[field]() }))
      .reduce((f1, f2) => ({ ...f1, ...f2 }));
  }

  get formElement() {
    return this.formElementRef?.nativeElement as HTMLFormElement;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _setFormValue(field: TField, value: any) {
    this.getFormControl(field)?.setValue(value);
  }

  private _getFormValue(field: TField) {
    return this.getFormControl(field)?.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.fields = Object.keys(this.form.controls) as TField[];

      this.get = this.fields.reduce((value, field) => ({
        ...value,
        [field]: <TResult>() => this._getFormValue(field) as TResult
      }), {} as typeof this.get);

      this.set = this.fields.reduce((value, field) => ({
        ...value,
        [field]: <Value>(value: Value) => this._setFormValue(field, value)
      }), {} as typeof this.set);
    }
  }

  selectChange(field: TField, value?: string) {
    this._setFormValue(field, value);

    this.closeSelect(field);
    // this.openNext(field);
    this.selectChanged.next(field);
  }

  selectFocus(select?: MatSelect) {
    if (!select?.focused) {
      select?.focus();
    }

    select?.open();
  }

  openNext(field: TField) {
    let currentIndex = this.fields.findIndex(fieldKey => fieldKey === field);
    let nextFieldControl: AbstractControl | null = null;
    while (currentIndex < this.fields.length && !nextFieldControl) {
      const nextField = this.fields[currentIndex + 1];
      nextFieldControl = this.getFormControl(nextField);
      if (nextFieldControl && !nextFieldControl.value) {
        this.focus(nextField)
      } else {
        nextFieldControl = null;
        currentIndex++;
      }
    }
    this.nextChanged.emit(field);
  }

  getFirstError(field: TField) {
    return Object.keys(this.getFormControl(field)?.errors || {}).find(() => true);
  }

  compareWith(option: SelectOption, value: unknown) {
    return option === value || option.value === value;
  }

  markAsTouched(field: TField) {
    this.getFormControl(field)?.markAsTouched();
  }

  markAllAsTouched() {
    this.form.markAllAsTouched();
  }

  getFormControl(field: TField) {
    return this.form.get(field);
  }

  closeSelect(field: TField) {
    this.selects?.find(select => select?.id === field)?.close();
  }

  clearSelect(field: TField) {
    this.selects?.find(select => select?.id === field)?.writeValue(undefined);
  }

  focus(field: TField) {
    setTimeout(() => this.getFormElement<HTMLElement>(field)?.focus(), 100);
  }

  getFormElement<ResultHtmlElement extends HTMLElement>(field: TField) {
    return this.formElement.querySelector('#' + field) as ResultHtmlElement;
  }

  defaultDisplayWith(option?: SelectOption) {
    return option?.label?.toString() || ''
  }

  autocompleteSelected<TOption extends SelectOption>(field: TField, selected: TOption) {
    this._setFormValue(field, selected.value);
    this.selectChanged.next(field);
    this.autocomplete.selected.next({ field, selected });
  }

  autocompleteClose(field: TField) {
    this.markAsTouched(field);
    this.autocomplete.closed.emit(field);
  }

  inputAutocompleteChange(field: TField, search: string) {
    this._setFormValue(field, search);
    this.autocomplete.input.emit(field);
  }

  autocompleteSearch(field: TField) {
    this.autocomplete.search.emit(field);
  }

  getTopInvalidField() {
    return this.fields.find(field => !this.getFormControl(field)?.valid);
  }

  dateTimeChange(field: TField, time: Date, date: DateTime, input: HTMLInputElement) {
    let dateTime = date;
    if (time) {
      const datetimeStringISO = dateTimeStringify.toISO(date, time);
      dateTime = convertToDateTime(datetimeStringISO)
    }

    this.markAsTouched(field);
    this._setFormValue(field, dateTime);
    input.value = formatDateTime(dateTime, dateTimeFormat.full) || '';
  }

  dateChange(field: TField, date: DateTime, input: HTMLInputElement, viewFormat?: string, valueFormat?: string) {
    this.markAsTouched(field);
    this._setFormValue(field, valueFormat ? formatDateTime(date, valueFormat) : date);
    input.value = formatDateTime(date, viewFormat || this.defaultDateFormat) || '';
  }

  initDate(_field: TField, date: DateTime | string, input: HTMLInputElement, viewFormat?: string) {
    const dateValue = typeof date === 'string' ? convertToDateTime(date) : date;
    input.value = formatDateTime(dateValue, viewFormat || this.defaultDateFormat) || '';
  }

  setFirstOption(field: TField, options?: SelectOption[]) {
    const { label = '', value } = options?.find(() => true) || {};
    const fieldInput = this.getFormElement<HTMLInputElement>(field);
    if (fieldInput) {
      fieldInput.value = label.toString();
      this.set[field](value);
    }
  }

  //#region Credit Card
  watchCreditCardNumber({ input, nextFieldId, image }: WatchInput) {
    if (image) {
      const cardNumber = input.value?.replace(/\D/g, '');
      const { card, isValid } = cardValidator.number(cardNumber);
      if (card) {
        const maskedValue = cardCorrector.cardNumber(input.value, card.gaps, Math.max(...card.lengths));
        if (maskedValue !== input.value) {
          this.set[input.name as TField](maskedValue);
        }

        if (isValid && !input.hasAttribute('was-focused')) {
          nextFieldId && document.getElementById(nextFieldId)?.focus();
          input.setAttribute('was-focused', 'true')
        }
      }
      else {
        this.set[input.name as TField](cardNumber);
      }

      const cardType = card?.type && knownCreditCards.some(item => item === card?.type) ? card.type : 'creditcard';
      const imageSrc = this._pathPipe.transform(cardType, 'payments');
      if (imageSrc !== image.src) {
        image.src = imageSrc;
      }
    }
  }

  watchExpirationDate({ input, nextFieldId }: WatchInput) {
    const { isValid, isPotentiallyValid } = cardValidator.expirationDate(input.value);
    if (!isPotentiallyValid) {
      this.set[input.name as TField](cardCorrector.expirationDate(input.value));
    } else {
      if (input.value.length > 2) {
        const result = cardCorrector.expirationDate(input.value);
        if (result !== input.value) {
          this.set[input.name as TField](result);
        }
      }
    }

    if (isValid && !input.hasAttribute('was-focused')) {
      nextFieldId && document.getElementById(nextFieldId)?.focus();
      input.setAttribute('was-focused', 'true')
    }
  }

  watchSecurityCode({ input }: WatchInput) {
    const { isPotentiallyValid } = cardValidator.cvv(input.value);
    if (!isPotentiallyValid) {
      const result = cardCorrector.securityCode(input.value);
      if (result !== input.value) {
        this.set[input.name as TField](result);
      }
    }
  }

  addWatchEvent(input: HTMLInputElement, callback?: () => void) {
    if (callback) {
      this._renderer.listen(input, 'keyup', (_: KeyboardEvent) => callback());
      this.watchingFields[input.name as TField] = callback;
    }
  }

  watchingFields: Partial<Record<TField, () => void>> = {};

  watch(data: WatchInput) {
    let callback = undefined;
    if (data.image) {
      callback = () => this.watchCreditCardNumber(data);
    } else {
      switch (data.input.autocomplete) {
        case 'cc-exp':
          callback = () => this.watchExpirationDate(data);
          break;
        case 'cc-csc':
          callback = () => this.watchSecurityCode(data);
          break;
      }
    }

    this.addWatchEvent(data.input, callback);
  }

  updateWatched() {
    window.setTimeout(() =>
      Object.keys(this.watchingFields).forEach(key => {
        const callback = this.watchingFields[key as TField];
        callback && callback();
      }));
  }
  //#endregion
}
