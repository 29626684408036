<ng-container *transloco="let t">
  <div class="flex-column flex-1 bc-default padding gap">
    <div class="flex-column text-1_25-1_25-500">
      {{t('paymentSection.result')}}
    </div>
    <div class="flex-column gap border padding">
      @for (item of log; track $index ){
      <div class="flex-start gap-half">
        @switch (item.color) {
        @case('selected'){
        @if($index === log.length - 1){
        <app-loading-icon
          size="button">
        </app-loading-icon>
        }
        }
        @case('textError'){
        <app-icon
          stroked="textError"
          size="default"
          name="close">
        </app-icon>
        }
        @case('textSuccessPrice'){
        <app-icon
          class="checked-success"
          size="default"
          name="checked">
        </app-icon>
        }}
        <div
          class="fw-500"
          [ngClass]="item.color ? 'color-' + item.color : ''"
          [innerHTML]="item.key ? t('paymentSection.' + item.key, item.data || {} ) : item.text">
        </div>
      </div>
      @if (item.isFinished){
      <div class="flex-end">
        <button mat-raised-button
          (click)="openConfirmationPage()"
          class="select-button"
          color="primary">
          {{t('paymentSection.openConfirmation')}}
        </button>
      </div>
      }
      }
    </div>
    @if(needRetry){
    <div class="flex-column slow border padding"
      [@paymentFormEnter]
      [@paymentFormLeave]>
      <app-payment-form #paymentForm></app-payment-form>
      <div class="flex margin-top-double">
        <app-icon-button
          name="lock"
          alt="reviewBooking.agreeButton"
          filled="default"
          [customButtonSize]="true"
          (click)="restrartPayment()"
          [transparent]="false"
          [buttonClass]="'select-button active w-100'"
          class="w-100"
          type="raised">
          {{t('paymentSection.reTry')}}
        </app-icon-button>
      </div>
    </div>
    }
  </div>
</ng-container>