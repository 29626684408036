import * as validator from 'card-validator';

export const cardValidator = validator;


const _knownCreditCards = ['amex', 'discover', 'mastercard', 'jcb', 'maestro', 'visa'] as const;
export type CardType = typeof _knownCreditCards[number];
export const knownCreditCards: CardType[] = [..._knownCreditCards];

export const cardCorrector = {
  cardNumber: (inputString: string, gapsArray: number[], maxLength: number) => {
    inputString = inputString.replace(/\D/g, '').slice(0, maxLength);

    let result = '';
    let previousGap = 0;

    for (const gap of gapsArray) {
      // Add characters up to the current cumulative position
      result += inputString.slice(previousGap, gap);

      // Add a space after the gap (if not at the end)
      if (gap < inputString.length) {
        result += ' ';
      }

      // Update the previous gap position
      previousGap = gap;
    }

    // Add any remaining characters after the last gap
    result += inputString.slice(previousGap);

    return result;
  },
  expirationDate: (inputString: string) => {
    const all = inputString.replace(/\D/g, '');

    return all.length > 2
      ? `${all.slice(0, 2)}/${all.slice(2, 4)}`
      : all + '/' === inputString
        ? inputString
        : all;
  },
  securityCode: (inputString: string) => inputString.replace(/\D/g, '').slice(0, 4)
}
