<ng-container *transloco="let t">
  @if(!unavailabilityReason){
  <div [ngClass]="['addon-pricing', size]">
    <div class="header"
      [class.with-price]="price !== undefined && !unavailabilityReason"
      *ngIf="currentAddOn">
      <div class="header-default uppercased no-wrap">
        {{addOnType | addOnType}}
      </div>

      @if(discountedPrice === undefined && !isAgeGroupsAddOn){
      @if (price){
      <div class="price-final">
        {{price | appCurrency}}
      </div>
      }
      @else {
      <div class="price-final color-textSuccessPrice">
        {{t('price.free')}}
      </div>
      }
      }
    </div>
    <div class="price"
      *ngIf="currentAddOn && !unavailabilityReason">
      <div class="age-groups" *ngIf="isAgeGroupsAddOn; else singlePriceTemplate">
        <div>
          <ng-container *ngIf="addOnsToPurchase.length">
            <span class="link" (click)="change()">
              {{t('button.change')}}
            </span>
          </ng-container>
        </div>
      </div>
      <ng-template #singlePriceTemplate>
        <div class="price-counter">
          <app-icon-button
            name="minus"
            filled="default"
            buttonSize="default"
            [disabled]="countToPurchase < 1 || !!unavailabilityReason"
            [stroked]="(countToPurchase > 0 && !unavailabilityReason) ? 'selected': 'border'"
            alt="decrease"
            (click)="decrease()">
          </app-icon-button>
          <span class="price-counter-text not-selectable">{{countToPurchase}}</span>
          <app-icon-button
            name="plus"
            filled="default"
            buttonSize="default"
            [disabled]="countToPurchase >= maxCountAddonsToPurchase || !!unavailabilityReason"
            [stroked]="(countToPurchase < maxCountAddonsToPurchase && !unavailabilityReason) ? 'selected': 'border'"
            alt="increase"
            (click)="increase()">
          </app-icon-button>
        </div>
        @if (discountedPrice !== undefined){
        <div class="flex-column">
          <div class="price-base-top">
            {{price | appCurrency}}
          </div>
          @if (discountedPrice === 0){
          <div class="price-final color-textSuccessPrice">
            {{t('price.free')}}
          </div>
          }
          @else {
          <div class="price-final">
            {{discountedPrice | appCurrency}}
          </div>
          }
        </div>
        }
      </ng-template>
    </div>
    <!-- @if (unavailabilityReason){
    <div class="price">
      <div class="not-found">
        {{t('price.' + unavailabilityReason.code , { value: unavailabilityReason.value})}}
      </div>
    </div>
    } -->
  </div>
  }
</ng-container>