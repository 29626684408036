<ng-container *transloco="let t">
  <div class="addon-select"
    *ngIf="totalResortAddOns">
    <div class="content-container">
      <div class="flex-column">
        <div class="section-header"
          [hidden]="hideHeader">
          <div class="section-header-text">
            <app-anchor type="section" [index]="orderNumber"></app-anchor>
            @if(isOrderedSection){
            {{orderNumber}}.&nbsp;
            }
            {{t('addOn.header')}}
            <div class="link"
              (click)="openPurchased(); toggler.toggleIsHidden()"
              *ngIf="toggler.isHidden && purchasedCount">
              ({{purchasedCount}})
            </div>
          </div>
          <app-section-toggler #toggler
            [isOnlyForTablet]="false">
          </app-section-toggler>
        </div>
        <div class="collapsable"
          [class.collapsed]="toggler.isHidden">
          <div class="section-subheader">
            <div class="section-subheader-text">
              {{t('addOn.subheader')}}&nbsp;
            </div>
            <div class="section-subheader-button">
              <app-icon-button
                (click)="openFilter()"
                name="sort"
                alt="addOn.filter.caption"
                stroked="iconStroke"
                [customButtonSize]="true"
                type="raised">
                {{t('addOn.filter.caption')}}
              </app-icon-button>
            </div>
          </div>

          @if(isBrowser && selectConfigs.length){
          <cdk-virtual-scroll-viewport #options
            [appendOnly]="true"
            class="card-options-container"
            orientation="horizontal"
            [minBufferPx]="minBufferPx"
            [maxBufferPx]="maxBufferPx"
            [itemSize]="itemSize">
            <ng-container *cdkVirtualFor="let selectConfig of selectConfigs; trackBy: 'id' | createTrackBy;">
              @if(addOns[selectConfig.id]){
              <app-select-option-v2
                #option
                [config]="selectConfig"
                [recommendation]="recommendations[selectConfig.id]"
                [sidebarContext]="{ template, width: 'default', position: 'end'  }">
                <ng-template #footer>
                  <app-pricing-details-v2
                    (selectClick)="selectAddOn(addOns[selectConfig.id], option)"
                    [config]="pricingConfigs[selectConfig.id]">
                    <app-addon-pricing
                      [currentAddOn]="addOns[selectConfig.id]"
                      [addOnsToPurchase]="toPurchase[selectConfig.id]"
                      [unavailabilityReason]="unavailabilityReason"
                      (countToPurchaseChanged)="updateCountToPurchase(addOns[selectConfig.id], $event)"
                      (ageGroupsSidebarChanged)="option.openDetails()">
                    </app-addon-pricing>
                  </app-pricing-details-v2>
                </ng-template>
              </app-select-option-v2>
              }
              <ng-template #template>
                <app-addon-details
                  [currentAddOn]="addOns[selectConfig.id]"
                  [images]="selectConfig.images">
                  <ng-template #pricing>
                    @if(addOns[selectConfig.id].pricingModel?.type === ageGroupAddonType ){
                    <app-addon-age-groups-pricing
                      [addOnsToPurchase]="toPurchase[selectConfig.id]"
                      (countToPurchaseChanged)="updateCountToPurchase(addOns[selectConfig.id], $event.quantity, $event.ageGroupId)"
                      [unavailabilityReason]="unavailabilityReason"
                      [currentAddOn]="addOns[selectConfig.id]">
                    </app-addon-age-groups-pricing>
                    }
                    @else {
                    <app-pricing-details-v2
                      (selectClick)="selectAddOn(addOns[selectConfig.id])"
                      [config]="pricingConfigs[selectConfig.id]">
                      <app-addon-pricing
                        [currentAddOn]="addOns[selectConfig.id]"
                        [addOnsToPurchase]="toPurchase[selectConfig.id]"
                        [unavailabilityReason]="unavailabilityReason"
                        (countToPurchaseChanged)="updateCountToPurchase(addOns[selectConfig.id], $event)">
                      </app-addon-pricing>
                    </app-pricing-details-v2>
                    }
                  </ng-template>
                </app-addon-details>
              </ng-template>
            </ng-container>
          </cdk-virtual-scroll-viewport>
          }
          @if (!selectConfigs.length){
          <div class="addon-select-not-found not-found">
            {{t('addOn.notFound')}}
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #addOnFilter>
  <app-sidebar-layout scope="addOn">
    <ng-template #content>
      <app-addon-filter class="sidebar-scroll-container"></app-addon-filter>
    </ng-template>
  </app-sidebar-layout>
</ng-template>
