import { EventEmitter, Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { AddOnCategory, AddOnSummaryDiscount, IAddOnSummaryDiscount, AddOnResponse, AddOnToPurchase, RatePlanType, Resort } from '@shared/api/be-api.generated';
import { DataService } from '@shared/services/data.service';
import { AddOnToPurchaseViewModel, AddonType, IQueryStringAddOn } from '@shared/models/common';
import { QueryParamsService } from './query-params.service';
import { maxCountAddonsToPurchase, tripProtectionCategory } from '@shared/consts/common';
import { PackageService } from './package.service';
import { arrayFrom } from '@shared/common';

export class AddOnSummaryDiscountView extends AddOnSummaryDiscount {
  constructor(item: IAddOnSummaryDiscount) {
    super(item);
  }
  get addOnKey() {
    return this.addOnId ?? '';
  }

  get ageGroupKey() {
    return this.ageGroupId ?? '';
  }
}


@Injectable()
export class AddOnService {
  constructor(
    private readonly _package: PackageService,
    private readonly _data: DataService,
    private readonly _queryParams: QueryParamsService,
  ) {
    this._queryParams.routeChanged.subscribe(() => this._initialize());
    this._package.setWhenDataChanged(({ resort, fromDate, toDate, ratePlanType, addOnOfferDiscounts }) => {
      this.discounts = {};
      addOnOfferDiscounts.forEach(item => this.putDiscount(item));

      if (resort && fromDate && toDate && ratePlanType) {
        this._changeRatePlanType(ratePlanType);
        this._setResortAddOns(resort, fromDate, toDate);
      }

      this._initialize();
    });
  }

  //#region members
  dataChanged = new EventEmitter();
  tripProtectionCategory = tripProtectionCategory;

  toPurchase: AddOnToPurchase[] = [];
  toPurchaseViewModel: AddOnToPurchaseViewModel[] = [];
  categories: string[] = [];
  resortAddOns: AddOnResponse[] = [];

  currentAddOns: AddOnResponse[] = [];
  category?: string;
  showPurchased?: boolean;
  showAvailable?: boolean;

  ratePlanTypeId!: string;

  availableAddOnsCategories: AddOnCategory[] = [];
  availableAddOnsCategoriesCodes: string[] = [];

  discounts: Record<string, Record<string, AddOnSummaryDiscount[]>> = {};

  tripProtectionAddon?: AddOnResponse;
  get isTripProtectionPurchased() {
    return this.toPurchase.some(item => item.addOnId === this.tripProtectionAddon?.addOnId);
  }

  get toPurchaseRecord() {
    const result: Record<string, number> = {};

    this.toPurchase
      .forEach((item) => item.addOnId && item.quantity && (result[item.addOnId] = item.quantity));

    return result;
  }

  get countPurchasedTripProtection() {
    return this.toPurchase.find(addOn => addOn.addOnId === this.tripProtectionAddon?.addOnId)?.quantity;
  }

  private get _allAddOns() {
    return this._data.values.addOns;
  }

  get allAddOnsCategories() {
    return this._data.values.addOnCategories;
  }
  //#endregion

  //#region private
  private _initialize() {
    this.resetFilters();
    this._setCategories();
    this._restorePurchasedFromQueryString();
    this._setAddOnsViewModel();
    this.setAddOns();
  }

  private _setCategories() {
    this.tripProtectionAddon = this.resortAddOns.find(addOn => addOn.category === this.tripProtectionCategory);
    this.categories = [];
    this.resortAddOns.forEach(addOn => addOn.category
      && addOn.category !== this.tripProtectionCategory
      && this.categories.indexOf(addOn.category) === -1
      && this.categories.push(addOn.category));
  }

  private _restorePurchasedFromQueryString() {
    const { addOns = [] } = this._queryParams.value;

    const availableAddOns = addOns.filter(addOn => this.resortAddOns
      .some(({ addOnId, pricingModel: { type, prices } = {} }) =>
        addOnId === addOn.addOnId
        && (type !== AddonType.PerAgeGroup || prices?.some(item => item.id === addOn.ageGroupId))));

    this.toPurchase = availableAddOns.map(item => new AddOnToPurchase({ ...item }));
    this._updateQueryString();
  }

  private _setAddOnsViewModel() {
    this.toPurchaseViewModel = [];
    this.toPurchase.map(item => {
      const countPurchased = item.quantity || 0;
      if (!countPurchased) {
        return;
      }

      const { addOnId, ageGroupId } = item;
      const { pricingModel, name, category } = this.resortAddOns.find(addOn => addOn.addOnId === addOnId) || {};

      const {
        agePeriod: { fromYear: from = 0, toYear: to = 0 } = {}
      } = pricingModel?.prices?.find(ageGroup => ageGroup.id === item.ageGroupId) || {};

      item.quantity = item.quantity && item.quantity > maxCountAddonsToPurchase
        ? maxCountAddonsToPurchase : item.quantity;

      const categoryData = !!category ? this.availableAddOnsCategories.find(addOnCategory => addOnCategory.code === category) : undefined;

      arrayFrom(countPurchased, index => {
        const { discountedPricePerItem, basePricePerItem } = this.getPrice({ addOnId, ageGroupId }, index)
        const pricePerItem = discountedPricePerItem != undefined ? discountedPricePerItem : basePricePerItem;

        const viewModel = this.toPurchaseViewModel.find(view =>
          view.addOnId === addOnId && view.ageGroupId === ageGroupId && view.pricePerItem === pricePerItem)

        if (viewModel) {
          viewModel.quantity = (viewModel.quantity ?? 0) + 1;
        } else {
          this.toPurchaseViewModel.push(
            new AddOnToPurchaseViewModel({
              ...item, name, from, to, pricePerItem, basePricePerItem,
              quantity: 1,
              addOnType: pricingModel?.type,
              categoryName: categoryData?.name,
              categoryCode: categoryData?.code,
            })
          )
        }
      });
    });
  }


  private _updateViewModels() {
    this._setAddOnsViewModel();
    this._updateQueryString();
    this.dataChanged.emit();
  }

  private _updateQueryString() {
    const addOns: IQueryStringAddOn[] = this.toPurchase.map(({
      addOnId, ageGroupId = undefined, quantity = 0 }) =>
      ({ addOnId, ageGroupId, quantity }));

    this._queryParams.patchQueryParams({ addOns });
  }

  private _changeRatePlanType({ ratePlanTypeId }: RatePlanType) {
    if (ratePlanTypeId && this.ratePlanTypeId !== ratePlanTypeId) {
      this.ratePlanTypeId = ratePlanTypeId;

      this.availableAddOnsCategories = this._data.values.addOnCategories
        .filter(({ ratePlanTypesIds = [] }) => !ratePlanTypesIds.length || ratePlanTypesIds.includes(this.ratePlanTypeId));
    }
  }

  private _setResortAddOns({ addOns = [] }: Resort, fromDate: DateTime, toDate: DateTime) {
    const availableAddOns = this._allAddOns.filter(({ addOnId = '', category, availabilityPeriod }) =>
      (!category || this.availableAddOnsCategories.some(item => item.code === category)) &&
      addOns.includes(addOnId) &&
      (!availabilityPeriod ||
        ((availabilityPeriod.start || toDate) <= toDate
          && ((availabilityPeriod.finish || fromDate) >= fromDate)))
    );

    this.toPurchase.forEach(item => {
      let addOn = availableAddOns.find(addOn => addOn.addOnId === item.addOnId);
      if (!addOn) { // if not available - cancel purchase
        addOn = this._allAddOns.find(addOn => addOn.addOnId === item.addOnId);
        addOn && this.cancelPurchase(addOn, item.ageGroupId);
      }
    });

    const unOrderedAddons = availableAddOns.filter(item => (item.orderNumber || -1) < 0);
    const orderedAddons = availableAddOns
      .filter(item => (item.orderNumber || -1) >= 0)
      .sort((item1, item2) => (item1.orderNumber || 0) > (item2.orderNumber || 0) ? 1 : -1);

    this.resortAddOns = [...orderedAddons, ...unOrderedAddons];
  }
  //#endregion

  //#region public
  resetFilters() {
    this.category = undefined;
    this.showPurchased = undefined;
    this.showAvailable = undefined;
  }

  setAddOns() {
    this.currentAddOns = this.resortAddOns.filter(addOn =>
      (!this.tripProtectionAddon || addOn.addOnId !== this.tripProtectionAddon.addOnId)
      && (this.category === undefined || addOn.category === this.category)
      && (this.showPurchased === undefined || this.toPurchase.some(tp => tp.addOnId === addOn.addOnId) === this.showPurchased)
      && (this.showAvailable === undefined
        || !!addOn.pricingModel?.price === this.showAvailable
        || !!addOn.pricingModel?.prices?.length === this.showAvailable
      )) || [];

    this.dataChanged.emit();
  }

  changeTripProtection(includeProtect: boolean) {
    if (this.tripProtectionAddon) {
      if (includeProtect) {
        if (this._package.adultsCount > 0) {
          this.purchase(this.tripProtectionAddon, this._package.adultsCount);
        }
      } else {
        this.cancelPurchase(this.tripProtectionAddon);
      }
    }
  }

  purchase(item: AddOnResponse, quantity: number, ageGroupId: string | undefined = undefined) {
    const addOnId = item.addOnId;

    if (quantity === 0) {
      this.cancelPurchase(item, ageGroupId);
    } else {
      let addOn = this.toPurchase.find(addOn => addOn.addOnId === addOnId && addOn.ageGroupId === ageGroupId);
      if (!addOn) {
        addOn = new AddOnToPurchase();
        this.toPurchase.push(addOn);
      }

      addOn.addOnId = addOnId;
      addOn.quantity = quantity;
      addOn.ageGroupId = ageGroupId;
    }

    this._updateViewModels();
  }

  cancelPurchase({ addOnId }: AddOnResponse, ageGroupId: string | undefined = undefined) {
    this.toPurchase = this.toPurchase.filter(addOn => !(addOn.addOnId === addOnId
      && (!ageGroupId || addOn.ageGroupId === ageGroupId)));
    this._updateViewModels();
  }

  getToPurchase({ addOnId }: AddOnResponse) {
    return this.toPurchase.find(addOn => addOn.addOnId === addOnId);
  }

  getToPurchaseViewModels(addOnId?: string) {
    return this.toPurchaseViewModel.filter(addOn => addOn.addOnId === addOnId);
  }

  setWhenDataChanged(onDataChanged: (data: AddOnService) => void, useWithFirstInit = true) {
    if (useWithFirstInit) {
      onDataChanged(this);
    }

    return this.dataChanged.subscribe(() => onDataChanged(this));
  }
  //#endregion

  //#region  Price
  getDiscounts(input: IAddOnSummaryDiscount) {
    const item = new AddOnSummaryDiscountView(input);
    const discounts = this.discounts[item.addOnKey] ? this.discounts[item.addOnKey][item.ageGroupKey] || [] : [];
    return discounts;
  }

  putDiscount(input: IAddOnSummaryDiscount) {
    const discount = new AddOnSummaryDiscountView(input);
    const { addOnKey, ageGroupKey } = discount;
    this.discounts[addOnKey] = this.discounts[addOnKey] || {};
    this.discounts[addOnKey][ageGroupKey] = this.discounts[addOnKey][ageGroupKey] || [];
    this.discounts[addOnKey][ageGroupKey].push(discount)
  }

  getDiscountedPrice(input: IAddOnSummaryDiscount, addOnIndex?: number) {
    const discounts = this.getDiscounts(input);
    const result = discounts.find(item => item.quantity && item.quantity > (addOnIndex || 0))
      || discounts.find(item => !item.quantity)

    return result?.discountedPrice;
  }

  getPrice(input: IAddOnSummaryDiscount, addOnIndex?: number) {
    const discountedPricePerItem = this.getDiscountedPrice(input, addOnIndex);
    const { pricingModel } = this.resortAddOns.find(addOn => addOn.addOnId === input.addOnId) || {};
    const { type: addOnType } = pricingModel || {};
    const { price: ageGroupPricePerItem } = pricingModel?.prices?.find(ageGroup => ageGroup.id === input.ageGroupId) || {};
    const basePricePerItem = (addOnType === AddonType.PerAgeGroup ? ageGroupPricePerItem : pricingModel?.price) || 0;

    return { discountedPricePerItem, basePricePerItem };
  }

  getBiggestDiscount(addOnId: string) {
    const discount = Object.values(this.discounts[addOnId] ?? {})
      .reduce((all, item) => [...all, ...item], [])
      .map(item => item.totalDiscount || 0);

    return Math.max(0, ...discount);
  }

  //#endregion
}
