<ng-container *transloco="let t">
  <div class="addon-age-groups-pricing">
    <div class="flex-column">
      <div class="addon-age-groups-item flex-between gap"
        *ngFor="let item of ageGroups">
        <div class="flex-start">
          {{t('child.fromTo', { from: item.from, to: item.to })}}
        </div>
        <div class="flex-between gap-half">
          @if(item.discountedPrice){
          <div class="flex-between gap-quarter">
            <div class="color-basePrice text-0_875-1-500 line-through">
              {{item.price | appCurrency}}
            </div>
            <div class="bold">
              {{item.discountedPrice | appCurrency}}
            </div>
          </div>
          }
          @else {
          <div class="bold">
            {{item.price | appCurrency}}
          </div>
          }
          <div>
            <app-icon-button
              alt="decrease"
              name="minus"
              filled="default"
              buttonSize="default"
              [disabled]="item.quantity <= 0 || !!unavailabilityReason"
              [stroked]="(item.quantity > 0 && !unavailabilityReason) ? 'selected': 'border'"
              (click)="decrease(item)">
            </app-icon-button>
            <span class="price-counter-text">{{item.quantity}}</span>
            <app-icon-button
              alt="increase"
              name="plus"
              filled="default"
              buttonSize="default"
              [disabled]="item.quantity >= maxCountToPurchase || !!unavailabilityReason"
              [stroked]="(item.quantity < maxCountToPurchase && !unavailabilityReason) ? 'selected': 'border'"
              (click)="increase(item)">
            </app-icon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>